import BalanceInputContainer from './BalanceInputContainer';

const MigrationSendBalance = () => {

  return (
    <div>
      <div className="mb-4 items-stretch"></div>
      <div className="w-100 relative">
        <BalanceInputContainer />
        <div className="absolute top-2 right-4 text-32px">KMA</div>
      </div>
    </div>
  );
};

export default MigrationSendBalance;
