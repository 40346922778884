import MobileNotSupportedModal from 'components/Modal/mobileNotSupported';
import CalamariBareNavbar from 'components/Navbar/CalamariBare';
import PageContent from 'components/PageContent';
import Squid2 from 'resources/images/squid2.png';
import userIsMobile from 'utils/ui/userIsMobile';
import { MigrationBurnContextProvider } from './MigrationBurnContext';
import MigrationHistory from './MigrationHistory';
import { MigrationHistoryContextProvider } from './MigrationHistoryContext';
import MigrationSendForm from './MigrationSendForm';

const MigrationPage = () => {
  let mobileNotSupportedModal = null;
  if (userIsMobile()) {
    mobileNotSupportedModal = <MobileNotSupportedModal />;
  }

  return (
    <div className="flex flex-col bg-migration min-h-screen min-w-[100vw] overflow-y-auto overflow-x-hidden">
      {mobileNotSupportedModal}
      <MigrationBurnContextProvider>
        <MigrationHistoryContextProvider>
          <CalamariBareNavbar withWallet />
          <PageContent className="flex h-full">
            <MigrationSendForm>
              <img
                style={{
                  zIndex: 10,
                  position: 'absolute',
                  width: '544px',
                  height: '784px',
                  top: '-172px',
                  right: '-500px'
                }}
                src={Squid2 as string}
                alt="Squid2"
              />
            </MigrationSendForm>
            <MigrationHistory />
          </PageContent>
        </MigrationHistoryContextProvider>
      </MigrationBurnContextProvider>
    </div>
  );
};

export default MigrationPage;
