import blackArrow from 'resources/images/black-arrow.svg';

const AdBanner = () => {
  return (
    <a
      className="w-ful  z-20 top-banner flex items-center justify-center gap-2 px-4 py-3"
      href="https://cedefi.manta.network/"
      rel="noreferrer"
      target="_blank">
      <div className="text-[16px] font-[500] text-black">
        Stake your assets on Manta CeDeFi now to earn CeFi/DeFi yield & Manta
        token rewards.
      </div>
      <img src={blackArrow} alt="" />
    </a>
  );
};

export default AdBanner;
