// @ts-nocheck
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTxStatus } from 'contexts/txStatusContext';
import { ethers } from 'ethers';
import { useMetamask } from 'contexts/metamaskContext';
import { validatePublicAddress } from 'utils/validation/validateAddress';
import { useWallet } from 'contexts/walletContext';
import Icon from 'components/Icon';
import { ConnectWalletButton } from 'components/Accounts/ConnectWallet';
import { getHasAuthToConnectEVMWalletNameStorage } from 'utils/persistence/connectAuthorizationStorage';
import { useBridgeData } from './BridgeContext/BridgeDataContext';

const BirdgeDestinationButton = ({ onChangeDestinationtInput }) => {
  const { destinationAddress, destinationChainIsEvm, originChainIsEvm } =
    useBridgeData();
  const { txStatus } = useTxStatus();
  const disabled = txStatus?.isProcessing();
  const { selectedWallet, selectedAccount: externalAccount } = useWallet();
  const { ethAddress } = useMetamask();
  const evmWalletName = getHasAuthToConnectEVMWalletNameStorage();
  const onClick = () => {
    if (!externalAccount && originChainIsEvm) {
      return;
    } else {
      handleClickGetAddress();
    }
  };

  const handleClickGetAddress = () => {
    if (disabled) {
      return;
    } else if (destinationChainIsEvm) {
      onChangeDestinationtInput(ethAddress);
    } else {
      onChangeDestinationtInput(externalAccount?.address);
    }
  };

  const getAddressIcon = () => {
    if (destinationChainIsEvm) {
      return <Icon name={evmWalletName || 'metamask'} className="w-6 h-6" />;
    } else {
      return (
        <img
          className="w-6 h-6"
          src={selectedWallet.logo.src}
          alt={selectedWallet.logo.alt}
        />
      );
    }
  };

  const getAccountName = () => {
    if (destinationChainIsEvm) {
      if (evmWalletName === 'subwallet') {
        return 'SubWallet';
      } else if (evmWalletName === 'talisman') {
        return 'Talisman';
      }
      return 'MetaMask';
    } else {
      return externalAccount?.name;
    }
  };

  const SelectedAccountText = () => {
    return (
      <div className="flex flex-row">
        <div className="block mr-2 min-w-full min-h-full">
          <i>{getAddressIcon()}</i>
        </div>
        <p className="block min-w-0 inline-block overflow-hidden overflow-ellipsis text-white font-normal">
          {getAccountName()}
        </p>
      </div>
    );
  };

  if (!externalAccount && originChainIsEvm) {
    return (
      <ConnectWalletButton
        className={classNames(
          'h-full ml-2 px-6 w-40 py-2',
          'unselectable-text text-center text-white rounded-lg gradient-button cursor-pointer text-[14px] font-semibold',
          { disabled: disabled }
        )}
      />
    );
  }

  if (!ethAddress && destinationChainIsEvm) {
    return (
      <ConnectWalletButton
        text="Connect EVM Wallets"
        className={classNames(
          'h-full ml-2 px-6 w-40 py-2',
          'unselectable-text text-center text-white rounded-lg gradient-button cursor-pointer text-[14px] font-semibold',
          { disabled: disabled }
        )}
      />
    );
  }

  const ButtonContents = () => {
    const destinationAccountIsMine =
      destinationAddress &&
      (destinationAddress === externalAccount?.address ||
        destinationAddress === ethAddress);
    if (destinationAccountIsMine) {
      return <SelectedAccountText />;
    } else {
      return 'Get Address';
    }
  };

  return (
    <button
      onClick={onClick}
      className={classNames(
        'h-full ml-2 px-6 w-40',
        'unselectable-text text-center text-white rounded-lg gradient-button cursor-pointer text-[14px] font-semibold',
        { disabled: disabled }
      )}>
      <ButtonContents />
    </button>
  );
};

const BridgeDestinationForm = () => {
  const {
    setDestinationAddress,
    destinationChain,
    originChain,
    destinationChainIsEvm,
    originChainIsEvm
  } = useBridgeData();
  const { txStatus } = useTxStatus();
  const disabled = txStatus?.isProcessing();
  const [inputValue, setInputValue] = useState('');

  // Clear input if origin and destination chain are swapped
  useEffect(() => {
    setInputValue('');
  }, [originChain, destinationChain]);

  const validateAddress = (maybeAddress) => {
    if (destinationChainIsEvm) {
      return ethers.utils.isAddress(maybeAddress);
    }
    return validatePublicAddress(maybeAddress);
  };

  const onChangeDestinationtInput = (value) => {
    if (value === '') {
      setInputValue('');
      setDestinationAddress(null);
    } else if (validateAddress(value)) {
      setInputValue(value);
      setDestinationAddress(value);
    } else {
      setInputValue(value);
      setDestinationAddress(null);
    }
  };

  // const placeholderMsg = `Enter ${
  //   originChainIsEvm ? 'substrate' : 'EVM'
  // } address`;

  const placeholderMsg = `Enter ${destinationChain.displayName} Address`;

  return (
    <div className="flex items-center flex-grow h-16 mt-6">
      <input
        id="recipientAddress"
        autoComplete="off"
        className={classNames(
          'h-full rounded-lg bg-white px-5 flex-1',
          'text-sm text-default dark:text-default outline-none rounded-lg',
          { disabled: disabled }
        )}
        onChange={(e) => onChangeDestinationtInput(e.target.value)}
        value={inputValue}
        disabled={disabled}
        placeholder={placeholderMsg}
      />
      <BirdgeDestinationButton
        onChangeDestinationtInput={onChangeDestinationtInput}
      />
    </div>
  );
};

export default BridgeDestinationForm;
