// @ts-nocheck
import React from 'react';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { useWallet } from 'contexts/walletContext';
import { useMetamask } from 'contexts/metamaskContext';
import { useTxStatus } from 'contexts/txStatusContext';
import { getSubstrateWallets } from 'utils';
import { setLastAccessedWallet } from 'utils/persistence/walletStorage';
import { getShouldShowMetamask } from 'utils/display/getShouldShowMetamask';
import { getHasAuthToConnectEVMWalletNameStorage } from 'utils/persistence/connectAuthorizationStorage';

const SubstrateWallets = ({ isMetamaskSelected, setIsMetamaskSelected }) => {
  const { txStatus } = useTxStatus();
  const disabled = txStatus?.isProcessing();
  const { setSelectedWallet, selectedWallet, authedWalletList } = useWallet();
  const substrateWallets = getSubstrateWallets();
  const enabledExtentions = substrateWallets.filter((wallet) =>
    authedWalletList.includes(wallet.extensionName)
  );
  const onClickWalletIconHandler = (wallet) => {
    if (disabled) return;
    setSelectedWallet(wallet);
    setLastAccessedWallet(wallet);
    setIsMetamaskSelected(false);
  };

  return enabledExtentions.map((wallet) => (
    <button
      className={classNames('px-3 py-3 rounded-lg', {
        'border border-dark-blue bg-light-blue':
          wallet.extensionName === selectedWallet?.extensionName &&
          !isMetamaskSelected,
        disabled: disabled
      })}
      key={wallet.extensionName}
      onClick={() => onClickWalletIconHandler(wallet)}>
      <img
        className="w-6 h-6 max-w-6 max-h-6"
        src={wallet.logo.src}
        alt={wallet.logo.alt}
      />
    </button>
  ));
};

const MetamaskWallet = ({ isMetamaskSelected, setIsMetamaskSelected }) => {
  const { txStatus } = useTxStatus();
  const disabled = txStatus?.isProcessing();
  const onClickMetamaskHandler = () => {
    !disabled && setIsMetamaskSelected(true);
  };
  const evmWalletName = getHasAuthToConnectEVMWalletNameStorage();
  return (
    <button
      className={classNames('px-3 py-3', {
        'border border-dark-blue bg-light-blue rounded-lg': isMetamaskSelected,
        disabled: disabled
      })}
      onClick={onClickMetamaskHandler}>
      <Icon
        className="w-6 h-6 max-w-6 max-h-6"
        name={evmWalletName || 'metamask'}
      />
    </button>
  );
};

const WalletSelectIconBar = ({ isMetamaskSelected, setIsMetamaskSelected }) => {
  const { ethAddress } = useMetamask();
  const shouldShowMetamask = getShouldShowMetamask();
  return (
    <>
      <SubstrateWallets
        isMetamaskSelected={isMetamaskSelected}
        setIsMetamaskSelected={setIsMetamaskSelected}
      />
      {shouldShowMetamask && ethAddress && (
        <MetamaskWallet
          isMetamaskSelected={isMetamaskSelected}
          setIsMetamaskSelected={setIsMetamaskSelected}
        />
      )}
    </>
  );
};

export default WalletSelectIconBar;
