import LoadingDark from 'resources/icons/loading-dark.svg';
import Loading from 'resources/icons/loading.svg';

function LoadingIcon({
  isDark = false,
  className = ''
}: {
  isDark?: boolean;
  className?: string;
}) {
  return (
    <img
      className={`${className} loading-spin inline-block`}
      src={isDark ? LoadingDark : Loading}
      alt="loading"
    />
  );
}

export default LoadingIcon;
