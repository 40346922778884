import React from 'react';
import classNames from 'classnames';

const Button: React.FC<ButtonPropTypes> = ({
  className = 'btn-primary',
  onClick,
  children,
  disabled,
  ...rest
}) => {
  return (
    <button
      className={classNames(
        `px-3 py-3 text-center font-semibold outline-none focus:outline-none rounded-md ${
          disabled ? 'btn-disabled' : ''
        }`,
        className
      )}
      onClick={onClick}
      disabled={disabled}
      {...rest}>
      {children}
    </button>
  );
};

interface ButtonPropTypes extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  onClick: () => void;
  disabled?: boolean;
  children: React.ReactNode;
}

export default Button;
