import { useConfig } from 'contexts/configContext';
import { ethers } from 'ethers';
import ABI from './abi.json';

export const useStakerBindContract = () => {
  const config = useConfig();

  const readContract = (provider: any) => {
    return new ethers.Contract(
      config.StakerBindContractOnMantaPacific,
      ABI.abi,
      new ethers.providers.Web3Provider(provider)
    );
  };

  const writeContract = (provider: any) => {
    return new ethers.Contract(
      config.StakerBindContractOnMantaPacific,
      ABI.abi,
      new ethers.providers.Web3Provider(provider).getSigner()
    );
  };

  return { readContract, writeContract };
};
