// @ts-nocheck
import { useState, useEffect } from 'react';
import DotLoader from 'components/Loaders/DotLoader';
import { useMetamask } from 'contexts/metamaskContext';
import classNames from 'classnames';
import { useConfig } from 'contexts/configContext';
import { useDebouncedCallback } from 'use-debounce';
import { useBridgeData } from '../BridgeContext/BridgeDataContext';
import TransferFeeDisplay from './TransferFeeDisplay';
import {
  queryCelerBridgeFee,
  generateCelerContractData,
  generateApproveData,
  queryTokenAllowance,
  queryTransactionReceipt
} from './Util';
import EvmBridge from './index';

const buttonText = [
  'Processing',
  'Approve MANTA',
  'Transfer', // ethereum to moonbeam
  'The received amount cannot cover fee',
  'Transfer', // show evm bridge modal
  'The amount is larger than liquidity pool'
];

// Transfer and approve button for the Ethereum chain
const EvmTransferButton = () => {
  const {
    destGasFee,
    originChain,
    destinationChain,
    senderAssetType,
    senderAssetTargetBalance
  } = useBridgeData();
  const config = useConfig();
  const { ethAddress, provider } = useMetamask();
  const [status, setStatus] = useState(0); // status, 0 = Processing, 1 = Approve, 2 = Transfer, 3 = The received amount cannot cover fee, 4 = Transfer, 5 = The amount is larger than liquidity pool
  const [isEstimatingFee, setIsEstimatingFee] = useState(true);
  const [bridgeFee, setBridgeFee] = useState(null);
  const [transferId, setTransferId] = useState('');
  const [showEvmBridgeModal, setShowEvmBridgeModal] = useState(false);

  const onClick = () => {
    if (status === 1) {
      onApproveClick();
    } else if (status === 2) {
      onTransferClick();
    } else if (status === 4) {
      if (!bridgeFee) {
        return;
      }
      setShowEvmBridgeModal(true);
    }
  };

  const getOriginChainInfo = () => {
    let sourceChainId = 0;
    let destinationChainId = 0;
    let celerContractAddress = '';
    let mantaContractAddress = '';
    let originChainGasFeeSymbol = '';
    let isEthereumToManta = null;
    if (originChain.name === 'ethereum') {
      sourceChainId = config.CelerEthereumChainId;
      destinationChainId = config.CelerMoonbeamChainId;
      celerContractAddress = config.CelerContractOnEthereum;
      mantaContractAddress = config.MantaContractOnEthereum;
      originChainGasFeeSymbol = 'ETH';
      isEthereumToManta = true;
    } else if (originChain.name === 'mantapacific') {
      sourceChainId = config.mantaPacificChainID;
      destinationChainId = config.CelerMoonbeamChainId;
      celerContractAddress = config.CelerContractOnMantaPacific;
      mantaContractAddress = config.MantaContractOnMantaPacific;
      originChainGasFeeSymbol = 'ETH';
      isEthereumToManta = true;
    } else if (destinationChain.name === 'mantapacific') {
      // moonbeam to pacific
      sourceChainId = config.CelerMoonbeamChainId;
      destinationChainId = config.mantaPacificChainID;
      celerContractAddress = config.CelerContractOnMoonbeam;
      mantaContractAddress = config.MantaContractOnMoonbeam;
      originChainGasFeeSymbol = 'GLMR';
      isEthereumToManta = false;
    } else {
      // moonbeam to ethereum
      sourceChainId = config.CelerMoonbeamChainId;
      destinationChainId = config.CelerEthereumChainId;
      celerContractAddress = config.CelerContractOnMoonbeam;
      mantaContractAddress = config.MantaContractOnMoonbeam;
      originChainGasFeeSymbol = 'GLMR';
      isEthereumToManta = false;
    }

    return {
      sourceChainId: sourceChainId,
      destinationChainId: destinationChainId,
      celerContractAddress: celerContractAddress,
      mantaContractAddress: mantaContractAddress,
      originChainGasFeeSymbol: originChainGasFeeSymbol,
      isEthereumToManta: isEthereumToManta
    };
  };

  useEffect(async () => {
    setStatus(0);
    setBridgeFee(null);
    setIsEstimatingFee(true);
    estimateGasFee();
  }, [senderAssetTargetBalance, ethAddress]);

  const estimateGasFee = useDebouncedCallback(async () => {
    try {
      // calculate transaction fee
      const originChainInfo = getOriginChainInfo();
      const amount = senderAssetTargetBalance
        .sub(destGasFee)
        .valueAtomicUnits.toString();

      // Query latest Celer bridge fee
      const latestBridgeFee = await queryCelerBridgeFee(
        originChainInfo.sourceChainId,
        originChainInfo.destinationChainId,
        senderAssetType.baseTicker,
        amount,
        config.CelerEndpoint
      );

      // estimate amount failed
      if (latestBridgeFee.err) {
        setBridgeFee(null);
        setStatus(5);
        setIsEstimatingFee(false);
        return;
      }

      if (latestBridgeFee.estimated_receive_amt < 0) {
        // The received amount cannot cover fee
        setStatus(3);
      } else {
        // eligible to transfer
        if (
          originChain.name === 'manta' &&
          (destinationChain.name === 'ethereum' ||
            destinationChain.name === 'mantapacific')
        ) {
          // show evm bridge modal
          setStatus(4);
        } else {
          // approve token from ethereum to moonbeam
          await queryAllowance(ethAddress, amount, 0);
        }
      }
      setBridgeFee(latestBridgeFee);
      setIsEstimatingFee(false);
    } catch (error) {
      console.error('Error:', error);
    }
  }, 1000);

  const queryTxStatus = async (txHash, transferId) => {
    const status = await queryTransactionReceipt(provider, txHash);
    const amount = senderAssetTargetBalance.valueAtomicUnits.toString();
    console.log('queryTxStatus', status);
    if (status === 1) {
      // transaction execute success
      setTransferId(transferId);
      setShowEvmBridgeModal(true);
      queryAllowance(ethAddress, amount, 0);
    } else if (status === 0) {
      // transaction execute failed
      queryAllowance(ethAddress, amount, 0);
    } else {
      // waiting for transaction mined by blockchain miner
      setTimeout(() => {
        queryTxStatus(txHash, transferId);
      }, 3 * 1000);
    }
  };

  // Call metamask to approve token
  const onApproveClick = async () => {
    if (isEstimatingFee) {
      return;
    }
    // Approve Celer Contract Address to spend user's token
    const data = await generateApproveData(
      originChain.name === 'ethereum'
        ? config.CelerContractOnEthereum
        : config.CelerContractOnMantaPacific,
      senderAssetTargetBalance.valueAtomicUnits.toString()
    );

    setStatus(0);
    await provider
      .request({
        method: 'eth_sendTransaction',
        params: [
          {
            from: ethAddress,
            to:
              originChain.name === 'ethereum'
                ? config.MantaContractOnEthereum
                : config.MantaContractOnMantaPacific,
            data: data
          }
        ]
      })
      .then(() => {
        const amount = senderAssetTargetBalance.valueAtomicUnits.toString();
        queryAllowance(ethAddress, amount);
      })
      .catch(() => {
        setStatus(1);
      });
  };

  const onTransferClick = async () => {
    const amount = senderAssetTargetBalance.valueAtomicUnits.toString();
    const sourceChainId =
      originChain.name === 'ethereum'
        ? config.CelerEthereumChainId
        : config.mantaPacificChainID;
    const destinationChainId = config.CelerMoonbeamChainId;

    // Generate data of Celer Contract
    const { data, transferId } = generateCelerContractData(
      sourceChainId,
      destinationChainId,
      ethAddress,
      originChain.name === 'ethereum'
        ? config.MantaContractOnEthereum
        : config.MantaContractOnMantaPacific,
      amount,
      bridgeFee.max_slippage,
      Date.now()
    );

    setStatus(0);
    try {
      const txHash = await provider.request({
        method: 'eth_sendTransaction',
        params: [
          {
            from: ethAddress,
            to:
              originChain.name === 'ethereum'
                ? config.CelerContractOnEthereum
                : config.CelerContractOnMantaPacific,
            data: data
          }
        ]
      });
      queryTxStatus(txHash, transferId);
    } catch (e) {
      setStatus(2);
    }
  };

  // Query user address allowance
  const queryAllowance = async (ethAddress, amount, retryTimes = 12) => {
    const allowance = await queryTokenAllowance(
      provider,
      originChain.name === 'ethereum'
        ? config.MantaContractOnEthereum
        : config.MantaContractOnMantaPacific,
      ethAddress,
      originChain.name === 'ethereum'
        ? config.CelerContractOnEthereum
        : config.CelerContractOnMantaPacific
    );

    if (parseInt(allowance) >= parseInt(amount)) {
      setStatus(2);
    } else {
      if (retryTimes === 0) {
        // show approve button
        setStatus(1);
      } else {
        setTimeout(() => {
          queryAllowance(ethAddress, amount, --retryTimes);
        }, 5 * 1000);
      }
    }
  };

  return (
    <div className="mt-7">
      <TransferFeeDisplay
        bridgeFee={bridgeFee}
        symbol={senderAssetType.baseTicker}
      />
      <div>
        {status === 0 ? (
          <LoadingIndicator />
        ) : (
          <button
            onClick={onClick}
            className={classNames(
              'gradient-button py-4 unselectable-text cursor-pointer',
              'text-center text-white rounded-lg w-full',
              {
                'filter brightness-50 cursor-not-allowed':
                  isEstimatingFee || status === 3 || status === 5
              }
            )}>
            {buttonText[status]}
          </button>
        )}

        <a
          href="https://cbridge.celer.network/169/1284/MANTA"
          target="_blank"
          className="block text-center gradient-text mt-4 cursor-pointer"
          rel="noreferrer">
          Powered by Celer Network
        </a>
      </div>
      {showEvmBridgeModal && (
        <EvmBridge
          transferId={transferId}
          latency={bridgeFee?.latency}
          maxSlippage={bridgeFee?.max_slippage}
          estimatedReceiveAmt={bridgeFee?.estimated_receive_amt}
          setShowEvmBridgeModal={setShowEvmBridgeModal}
        />
      )}
    </div>
  );
};

const LoadingIndicator = () => {
  return (
    <div className="gradient-button py-4 w-full filter rounded-lg text-white text-center font-semibold brightness-50 cursor-not-allowed">
      Processing&nbsp;
      <DotLoader />
    </div>
  );
};

export default EvmTransferButton;
