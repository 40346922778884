import { API_STATE, useSubstrate } from 'contexts/substrateContext';
import { useWallet } from 'contexts/walletContext';
import { useMigrationBurn } from 'pages/MigrationPage/MigrationBurnContext';
import getZkTransactBalanceText from 'utils/display/getZkTransactBalanceText';

const useMigrationBalanceText = () => {
  const { apiState } = useSubstrate();
  const { publicBalance } = useMigrationBurn();
  const { selectedAccount: externalAccount } = useWallet();

  const apiIsDisconnected =
    apiState === API_STATE.ERROR || apiState === API_STATE.DISCONNECTED;

  const balanceText = getZkTransactBalanceText(
    publicBalance?.toString(),
    apiIsDisconnected
  );

  const shouldShowPublicLoader = Boolean(
    externalAccount?.address && !balanceText
  );

  return { balanceText, shouldShowLoader: shouldShowPublicLoader };
};

export default useMigrationBalanceText;
