// @ts-nocheck
import React from 'react';
import ChainSelect from 'pages/BridgePage/ChainSelect';
import SendButton from 'pages/BridgePage/SendButton';
import { useTxStatus } from 'contexts/txStatusContext';
import { useConfig } from 'contexts/configContext';
import classNames from 'classnames';
import Icon from 'components/Icon';
import DowntimeModal from 'components/Modal/downtimeModal';
import MobileNotSupportedModal from 'components/Modal/mobileNotSupported';
import userIsMobile from 'utils/ui/userIsMobile';
import { useBridgeData } from './BridgeContext/BridgeDataContext';
import BridgeAssetSelect from './BridgeAssetSelect';
import BridgeFeeDisplay from './BridgeFeeDisplay';
import BridgeDestinationForm from './BridgeDestinationForm';
import ExploreEcosystem from './ExploreEcosystem';
// import AssociatedBridges from './AssociatedBridges';

const BridgeForm = () => {
  const config = useConfig();
  const { txStatus } = useTxStatus();
  const disabled = txStatus?.isProcessing();
  const {
    originChain,
    originChainOptions,
    setOriginChain,
    destinationChain,
    destinationChainOptions,
    setDestinationChain,
    switchOriginAndDestination,
    originChainIsEvm,
    destinationChainIsEvm
  } = useBridgeData();

  const onClickSwitchOriginAndDestination = () => {
    if (!disabled) {
      switchOriginAndDestination();
    }
  };

  let warningModal = <div />;
  if (config.DOWNTIME) {
    warningModal = <DowntimeModal />;
  } else if (userIsMobile()) {
    warningModal = <MobileNotSupportedModal />;
  }

  const shouldRedirectToCBridgePacific2Moonbeam =
    originChain.name === 'mantapacific' && destinationChain.name === 'moonbeam'; // pacific to moonbeam https://cbridge.celer.network/169/1284/MANTA
  const shouldRedirectToCBridgeMoonbeam2Pacific =
    originChain.name === 'moonbeam' && destinationChain.name === 'mantapacific'; // moonbeam to pacific https://cbridge.celer.network/1284/169/MANTA

  const shouldShowDestinationForm = originChainIsEvm || destinationChainIsEvm;
  const shouldShowBridgeFee = !(
    originChain.name === 'ethereum' ||
    destinationChain.name === 'ethereum' ||
    originChain.name === 'mantapacific' ||
    destinationChain.name === 'mantapacific'
  );

  const renderContent = () => {
    if (shouldRedirectToCBridgePacific2Moonbeam) {
      return (
        <a
          href="https://cbridge.celer.network/169/1284/MANTA"
          target="_blank"
          className={classNames(
            'gradient-button py-4 unselectable-text text-center text-white mt-7',
            'rounded-lg w-full hover:text-white'
          )}
          rel="noreferrer">
          Use Celer to transfer MANTA from Manta Pacific to Moonbeam
        </a>
      );
    }

    if (shouldRedirectToCBridgeMoonbeam2Pacific) {
      return (
        <a
          href="https://cbridge.celer.network/1284/169/MANTA"
          target="_blank"
          className={classNames(
            'gradient-button py-4 unselectable-text text-center text-white mt-7',
            'rounded-lg w-full hover:text-white'
          )}
          rel="noreferrer">
          Use Celer to transfer MANTA from Moonbeam to Manta Pacific
        </a>
      );
    }

    return (
      <>
        <div className="flex flex-col flex-y gap-4">
          <div>
            <BridgeAssetSelect />
          </div>
        </div>
        {shouldShowDestinationForm && <BridgeDestinationForm />}
        {shouldShowBridgeFee && <BridgeFeeDisplay />}
        <SendButton />
      </>
    );
  };

  return (
    <div className="2xl:inset-x-0 justify-center min-h-full flex flex-col gap-6 items-center pb-2 pt-12">
      {warningModal}
      <div className="flex items-start">
        <div className="flex flex-col px-3 py-4 sm:p-8 bg-secondary rounded-lg">
          <div className="flex gap-5 flex-row items-end mb-6">
            <ChainSelect
              chain={originChain}
              chainOptions={originChainOptions}
              setChain={setOriginChain}
              isOriginChain={true}
            />
            <Icon
              name="leftRightArrow"
              onClick={onClickSwitchOriginAndDestination}
              className={classNames('mx-auto pb-7 cursor-pointer', {
                disabled: disabled
              })}
            />
            <ChainSelect
              chain={destinationChain}
              chainOptions={destinationChainOptions}
              setChain={setDestinationChain}
              isOriginChain={false}
            />
          </div>
          {renderContent()}
        </div>
        <div className="ml-5">
          <ExploreEcosystem />
        </div>
      </div>
    </div>
  );
};

export default BridgeForm;
