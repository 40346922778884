import MantaStake from 'resources/icons/ecosystem/manta-stake.png';
import MantaDex from 'resources/icons/ecosystem/manta-dex.png';
import JumboShrimps from 'resources/icons/ecosystem/jumbo-shrimps.png';
import Bifrost from 'resources/icons/ecosystem/bi-frost.png';
import Stella from 'resources/icons/ecosystem/stella.webp';
export const EcosystemArr = [
  {
    enabled: true,
    name: 'Staking',
    link: '/stake',
    desc: 'Stake your $MANTA to secure the network and earn rewards',
    icon: MantaStake
  },
  {
    enabled: true,
    name: 'MantaDEX',
    link: 'https://mantadex.com',
    desc: 'Swap and provide liquidity on MantaDex',
    icon: MantaDex,
    outWebsite: true
  },
  {
    enabled: true,
    name: 'StellaSwap',
    link: 'https://app.stellaswap.com/zh-CN/exchange/swap',
    desc: 'Provide liquidity on Moonbeam network\n($MANTA token contract on Moonbeam: 0xfFFffFFf7D3875460d4509eb8d0362c611B4E841)',
    icon: Stella,
    outWebsite: true
  },
  {
    enabled: true,
    name: 'JumboShrimps',
    desc: 'Deposit $MANTA for a chance to win the entire staking reward pool',
    icon: JumboShrimps,
    link: 'https://app.jumboshrimps.xyz/',
    outWebsite: true
  },
  {
    enabled: true,
    name: 'Bifrost',
    desc: 'Earn rewards by staking $MANTA and free up your liquidity',
    icon: Bifrost,
    link: 'https://bifrost.app/vstaking/vMANTA',
    outWebsite: true
  }
];
