import React from 'react';

const StakeErrorDisplay = () => {
  const primaryText = 'Network Error';
  const secondaryText = 'Please check your internet connection';
  return (
    <div className="w-full p-6 shadow-2xl bg-secondary rounded-lg text-default">
      <h1 className="text-sm">{primaryText}</h1>
      <h1 className="text-sm mt-4">{secondaryText}</h1>
    </div>
  );
};

export default StakeErrorDisplay;
