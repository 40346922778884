// @ts-nocheck
import WALLET_NAME from 'constants/WalletConstants';
import NETWORK from 'constants/NetworkConstants';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTxStatus } from 'contexts/txStatusContext';
import MantaLoading from 'components/Loading';
import { ConnectWalletButton } from 'components/Accounts/ConnectWallet';
import { useMetamask } from 'contexts/metamaskContext';
import { API_STATE, useSubstrate } from 'contexts/substrateContext';
import Chain from 'types/Chain';
import { useConfig } from 'contexts/configContext';
import { useMantaWallet } from 'contexts/mantaWalletContext';
import { useWallet } from 'contexts/walletContext';
import { xTokenContractAddressList } from 'eth/EthXCM';
import BN from 'bn.js';
import { ethers } from 'ethers';
import { Checkbox } from 'element-react';
import LoadingIcon from 'components/LoadingIcon';
import { useBridgeTx } from './BridgeContext/BridgeTxContext';
import { useBridgeData } from './BridgeContext/BridgeDataContext';
import EvmTransferButton from './EvmBridge/ApproveTransferButton';
import BridgeABI from './EvmBridge/abi/bridge.json';

const ValidationButton = () => {
  const config = useConfig();
  const { apiState, api } = useSubstrate();
  const { showChangeNetworkNotification } = useMantaWallet();
  const { selectedWallet, selectedAccount: externalAccount } = useWallet();
  const {
    senderAssetType,
    minInput,
    originChain,
    originChainIsEvm,
    destinationChain,
    destinationChainIsEvm,
    destinationAddress,
    senderAssetTargetBalance
  } = useBridgeData();
  const { txIsOverMinAmount, userHasSufficientFunds, userCanPayOriginFee } =
    useBridgeTx();
  const { ethAddress, chainId } = useMetamask();
  const { txStatus, EVMBridgeProcessing } = useTxStatus();
  const disabled = txStatus?.isProcessing();
  const [GLMRBalance, setGLMRBalance] = useState('');
  const [checkedNote, setCheckedNote] = useState(false);
  const apiIsDisconnected =
    apiState === API_STATE.ERROR || apiState === API_STATE.DISCONNECTED;

  const evmIsEnabled = originChainIsEvm || destinationChainIsEvm;

  let validationMsg = null;
  let isConnectWallet = false;
  let isSwitchNetwork = false;
  let connectWalletText = 'Connect Wallet';
  let evmChain;
  if (originChain.name === 'ethereum') {
    evmChain = Chain.Ethereum(config);
  } else if (originChain.name === 'mantapacific') {
    evmChain = Chain.MantaPacific(config);
  } else {
    evmChain =
      config.NETWORK_NAME === NETWORK.MANTA
        ? Chain.Moonbeam(config)
        : Chain.Moonriver(config);
  }
  const evmChainId = evmChain.ethChainId;

  const isManta2Manta =
    originChain.name === 'mantapacific' ||
    destinationChain.name === 'mantapacific';
  const isFromAtlantic2Pacific =
    originChain.name === 'manta' && destinationChain.name === 'mantapacific';
  const isFromMantaToMoonbeam =
    originChain.name === 'manta' && destinationChain.name === 'moonbeam';
  const isMRLAsset = Object.keys(xTokenContractAddressList)
    .filter((name) => name !== 'MANTA')
    .includes(senderAssetType.baseTicker);

  useEffect(() => {
    async function getGLMRBalance() {
      const result = await api.query.assets.account(
        10,
        externalAccount.address
      );
      const balanceString = result?.value?.balance?.toString();
      setGLMRBalance(balanceString);
    }
    if (isFromMantaToMoonbeam && isMRLAsset) {
      getGLMRBalance();
    }
  });

  const [showCelerNotice, setShowCelerNotice] = useState(false);

  if (!externalAccount) {
    isConnectWallet = true;
  } else if (
    originChain.name === 'statemint' &&
    selectedWallet?.extensionName === WALLET_NAME.MANTA
  ) {
    validationMsg = 'Please use Subwallet to support AssetHub chain';
  } else if (!ethAddress && (originChainIsEvm || isFromAtlantic2Pacific)) {
    isConnectWallet = true;
    connectWalletText = 'Connect EVM Wallets';
  } else if (apiIsDisconnected) {
    validationMsg = 'Connecting to network';
  } else if (
    evmIsEnabled &&
    Number(chainId) !== Number(evmChainId) &&
    !EVMBridgeProcessing
  ) {
    isSwitchNetwork = true;
  } else if (
    selectedWallet?.extensionName === WALLET_NAME.MANTA &&
    showChangeNetworkNotification
  ) {
    validationMsg = 'Switch Networks in Manta Wallet';
  } else if (!senderAssetTargetBalance) {
    validationMsg = 'Enter amount';
  } else if (userHasSufficientFunds() === false && !EVMBridgeProcessing) {
    validationMsg = 'Insufficient balance';
  } else if (evmIsEnabled && !destinationAddress) {
    validationMsg = `Enter ${originChainIsEvm ? 'substrate' : 'EVM'} address`;
  } else if (
    originChain.name !== 'mantapacific' &&
    originChain.name !== 'ethereum' &&
    userCanPayOriginFee() === false &&
    !EVMBridgeProcessing
  ) {
    validationMsg = `Insufficient ${originChain.nativeAsset.ticker} to pay origin fee`;
  } else if (txIsOverMinAmount() === false) {
    const MIN_INPUT_DIGITS = 6;
    validationMsg = `Minimum ${
      senderAssetType.ticker
    } transaction is ${minInput.toDisplayString(MIN_INPUT_DIGITS)}`;
  } else if (isManta2Manta && senderAssetTargetBalance.toString() < 2) {
    validationMsg = 'The minimum transfer amount is 2 MANTA';
  } else if (showCelerNotice) {
    // must lte 10000 per tx, cele limit
    validationMsg = 'Exceeded Transaction Limit';
  } else if (isFromMantaToMoonbeam && isMRLAsset) {
    // transfer MRL assets from manta to moonbema
    const minGasFee = '56362740000000000';
    if (new BN(GLMRBalance).lt(new BN(minGasFee))) {
      validationMsg = 'Insufficient GLMR on Manta to pay destination fee';
    }
  } else if (senderAssetType.ticker === 'MANTA' && !checkedNote) {
    validationMsg = "Please Confirm You've Read the Above";
  }

  const handleChange = (value: boolean) => {
    setCheckedNote(!!value);
  };

  useEffect(() => {
    async function getEpochVolumes() {
      if (!senderAssetTargetBalance) return;
      const contract = new ethers.Contract(
        config.CelerContractOnMoonbeam,
        BridgeABI.abi,
        new ethers.providers.JsonRpcProvider(config.MOONBEAM_RPC)
      );
      const pacific2MantaEpochAmount = ethers.utils.formatEther(
        await contract.epochVolumes(config.MantaContractOnMoonbeam)
      );

      const contract2 = new ethers.Contract(
        config.CelerContractOnMantaPacific,
        BridgeABI.abi,
        new ethers.providers.JsonRpcProvider(config.MANTA_PACIFIC_RPC)
      );
      const manta2PacificEpochAmount = ethers.utils.formatEther(
        await contract2.epochVolumes(config.MantaContractOnMantaPacific)
      );

      const maxAmount = 2000000;
      const isManta2Pacific = destinationChain.name === 'mantapacific';
      const isPacific2Manta = originChain.name === 'mantapacific';
      const showNoticeCondition1 =
        isManta2Pacific &&
        Number(senderAssetTargetBalance.toString()) +
          Number(manta2PacificEpochAmount) >
          maxAmount;
      const showNoticeCondition2 =
        isPacific2Manta &&
        Number(senderAssetTargetBalance.toString()) +
          Number(pacific2MantaEpochAmount) >
          maxAmount;
      const showNoticeCondition3 =
        Number(senderAssetTargetBalance.toString()) >
        config.MaxAmountPerTxCelerLimit;
      if (
        isManta2Manta &&
        senderAssetTargetBalance &&
        (showNoticeCondition1 || showNoticeCondition2 || showNoticeCondition3)
      ) {
        setShowCelerNotice(true);
      } else {
        setShowCelerNotice(false);
      }
    }

    getEpochVolumes().catch(console.log);
  }, [senderAssetTargetBalance, isManta2Manta]);

  const ValidationText = ({ validationMsg }) => {
    return (
      <div
        className={classNames(
          'gradient-button py-4 unselectable-text text-center text-white mt-7',
          'rounded-lg w-full filter brightness-50 cursor-not-allowed'
        )}>
        {validationMsg}
      </div>
    );
  };

  const shouldShowSendButton =
    !disabled && !isConnectWallet && !validationMsg && !isSwitchNetwork;
  const shouldShowConnectWallet = !disabled && isConnectWallet;
  const shouldShowValidation =
    !disabled && !isConnectWallet && validationMsg && !EVMBridgeProcessing;
  const shouldShowSwitchNetwork =
    !disabled && !isConnectWallet && !validationMsg && isSwitchNetwork;

  let isEvmTransfer = false;
  if (
    originChain.name === 'ethereum' ||
    destinationChain.name === 'ethereum' ||
    originChain.name === 'mantapacific' ||
    destinationChain.name === 'mantapacific'
  ) {
    isEvmTransfer = true;
  }

  return (
    <>
      {disabled && <MantaLoading className="py-3" />}
      {senderAssetType.ticker === 'MANTA' && (
        <div className="w-138 flex items-start gap-2 bg-secondary rounded-lg mt-6">
          <Checkbox checked={checkedNote} onChange={handleChange} />
          <div className="leading-5 text-sm">
            Please avoid sending MANTA from Atlantic or Moonbeam (xcMANTA) to
            centralized exchanges or other wallet addresses that you do not
            control.{' '}
            <span className="font-bold">
              YOU WILL HAVE THE RISK OF LOSING YOUR ASSETS
            </span>
            . When withdrawing MANTA from centralized exchanges, please withdraw
            to Manta Pacific network ONLY.
          </div>
        </div>
      )}
      {shouldShowSendButton &&
        (isEvmTransfer ? <EvmTransferButton /> : <SendButton />)}
      {shouldShowConnectWallet && (
        <ConnectWalletButton
          text={connectWalletText}
          className={classNames(
            'gradient-button py-4 unselectable-text cursor-pointer mt-7',
            'text-center text-white rounded-lg w-full'
          )}
        />
      )}
      {shouldShowValidation && <ValidationText validationMsg={validationMsg} />}
      {shouldShowSwitchNetwork && <SwitchNetworkButton />}

      {showCelerNotice && (
        <>
          <a
            href="https://cbridge.celer.network/169/1284/MANTA"
            target="_blank"
            className="block text-center gradient-text mt-4 text-xss cursor-pointer"
            rel="noreferrer">
            Powered by Celer Network
          </a>
          <div className="w-138 mt-4 p-2 bg-red-1 text-red-warning border border-red border-solid rounded-lg">
            <div className="font-bold">Transaction Limit: </div>
            <div>
              1. For a single transaction, the maximum amount allowed is 400,000
              MANTA.
            </div>
            <div>
              2. For all our users, there&apos;s a joint limit of 2,000,000
              MANTA that can be transferred in total every 30 minutes.
            </div>
          </div>
        </>
      )}
    </>
  );
};

const SwitchNetworkButton = () => {
  const config = useConfig();
  const { switchNetwork, isSwitchingNetwork } = useMetamask();
  const { originChain } = useBridgeData();

  let networkName;
  if (originChain.name === 'ethereum') {
    networkName = 'Ethereum';
  } else if (originChain.name === 'mantapacific') {
    networkName = 'MantaPacific';
  } else {
    networkName =
      config.NETWORK_NAME === NETWORK.MANTA ? 'Moonbeam' : 'Moonriver';
  }

  const onClick = () => {
    switchNetwork(networkName);
  };

  const displayNetworkName =
    networkName === 'MantaPacific' ? 'Manta Pacific' : networkName;

  return (
    <button
      onClick={onClick}
      className={classNames(
        'gradient-button py-4 unselectable-text cursor-pointer mt-7',
        'text-center text-white rounded-lg w-full',
        { 'filter brightness-50 cursor-not-allowed': isSwitchingNetwork }
      )}>
      <span>{`Switch Network to ${displayNetworkName}`}</span>
      {isSwitchingNetwork && <LoadingIcon className="ml-2" />}
    </button>
  );
};

const SendButton = () => {
  const { send, isValidToSend } = useBridgeTx();

  const onClick = () => {
    send();
  };

  return (
    <button
      onClick={onClick}
      className={classNames(
        'gradient-button py-4 unselectable-text cursor-pointer mt-7',
        'text-center text-white rounded-lg w-full',
        { 'filter brightness-50 cursor-not-allowed': !isValidToSend() }
      )}>
      Submit
    </button>
  );
};

export default ValidationButton;
