// @ts-nocheck
const NETWORK = {
  CALAMARI: 'Calamari',
  MANTA: 'Manta',
};

export const SS58 = {
  MANTA: 77,
  CALAMARI: 78,
};

export default NETWORK;
