// @ts-nocheck
import GlobalContexts from 'contexts/globalContexts';
import {
  BridgePage,
  MigrationLandingPage,
  MigrationPage,
  SendPage,
  StakePage
} from 'pages';
import { CalamariBasePage, MantaBasePage } from 'pages/BasePage';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';

const MantaRoutes = () => {
  return (
    <MantaBasePage>
      <Routes>
        <Route path="manta">
          <Route index element={<Navigate to="stake" />} />
          <Route path="bridge" element={<BridgePage />} exact />
          {/* <Route path="transact" element={<SendPage />} exact /> */}
          <Route path="stake" element={<StakePage />} exact />
          <Route path="*" element={<Navigate to="stake" />} />
        </Route>
      </Routes>
    </MantaBasePage>
  );
};

const CalamariRoutes = () => {
  return (
    <CalamariBasePage>
      <Routes>
        <Route path="calamari">
          <Route index element={<Navigate to="stake" />} />
          <Route path="bridge" element={<BridgePage />} exact />
          <Route path="transact" element={<SendPage />} exact />
          <Route path="stake" element={<StakePage />} exact />
          <Route path="migrate" element={<MigrationPage />} exact />
          <Route
            path="migrate-landing"
            element={<MigrationLandingPage />}
            exact
          />
          <Route path="*" element={<Navigate to="stake" />} />
        </Route>
      </Routes>
    </CalamariBasePage>
  );
};

const RedirectRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="/manta/stake" replace />} exact />
      <Route
        path="/stake"
        element={<Navigate to="/manta/stake" replace />}
        exact
      />
      <Route
        path="/manta/transact"
        element={<Navigate to="/manta/stake" replace />}
      />
    </Routes>
  );
};

const AppRouter = () => {
  return (
    <Router>
      <GlobalContexts>
        <RedirectRoutes />
        <MantaRoutes />
        <CalamariRoutes />
      </GlobalContexts>
    </Router>
  );
};

export default AppRouter;
