// @ts-nocheck
import { useEffect, useState } from 'react';
import Balance from 'types/Balance';
import BN from 'bn.js';
import Icon from 'components/Icon';
import { Tooltip } from 'element-react';
import DotLoader from 'components/Loaders/DotLoader';
import { useBridgeData } from '../BridgeContext/BridgeDataContext';

const TransferFeeDisplay = (params) => {
  const {
    originGasFee,
    destGasFee,
    senderAssetType,
    destinationChain,
    originChain
  } = useBridgeData();
  const bridgeFee = params.bridgeFee;
  const symbol = params.symbol;
  const [gasFee, setGasFee] = useState([]);

  useEffect(() => {
    if (originGasFee.isZero()) {
      return;
    }
    const estimateReceive = bridgeFee
      ? new Balance(
        senderAssetType,
        new BN(bridgeFee.estimated_receive_amt)
      ).sub(destGasFee)
      : '--';

    const celerFee = bridgeFee
      ? new Balance(
        senderAssetType,
        new BN(bridgeFee.amount).sub(new BN(bridgeFee.estimated_receive_amt))
      )
      : '--';
    let bridgeFees = bridgeFee ? celerFee.add(destGasFee) : '--';

    const destIsEthereum =
      destinationChain.name === 'ethereum' ||
      destinationChain.name === 'mantapacific';
    if (destIsEthereum && bridgeFee) {
      bridgeFees = bridgeFees.add(originGasFee);
    }
    // console.log('celerFee', celerFee.toDisplayString());
    // console.log('destGasFee', destGasFee.toDisplayString());
    // console.log('originGasFee', originGasFee.toDisplayString());

    const bridgeFeesTooltip = (
      <div
        style={{
          width: '300px',
          fontSize: '14px',
          color: 'white'
        }}>
        {destIsEthereum && (
          <>
            <div className="flex flex-row justify-between mb-2">
              <span>The Network Fee:</span>
              <span>{bridgeFee ? originGasFee.toDisplayString() : '--'}</span>
            </div>
            <div className="flex flex-row justify-between mb-2">
              <span>The XCM Execution Fee:</span>
              <span>{bridgeFee ? destGasFee.toDisplayString() : '--'}</span>
            </div>
            <div className="flex flex-row justify-between mb-2">
              <span>The cBridge Fee:</span>
              <span>{bridgeFee ? celerFee.toDisplayString() : '--'}</span>
            </div>

            <div className="mt-6">
              <span className="font-semibold">The Network Fee</span> covers the
              gas cost for transfers on the Manta Atlantic network.
            </div>
            <div className="mt-6">
              <span className="font-semibold">The XCM Execution Fee</span>{' '}
              covers the transfer cost from Manta Atlantic to Moonbeam via XCM.
            </div>
            <div className="mt-6">
              <span className="font-semibold">The cBridge Fee</span> is for
              transferring via Celer network.
            </div>
          </>
        )}
        {!destIsEthereum && (
          <>
            <div className="flex flex-row justify-between mb-2">
              <span>The XCM Execution Fee:</span>
              <span>{bridgeFee ? destGasFee.toDisplayString() : '--'}</span>
            </div>
            <div className="flex flex-row justify-between mb-2">
              <span>The cBridge Fee:</span>
              <span>{bridgeFee ? celerFee.toDisplayString() : '--'}</span>
            </div>

            <div className="mt-6">
              <span className="font-semibold">The XCM Execution Fee</span>{' '}
              covers the transfer cost from Moonbeam to Manta Atlantic via XCM.
            </div>
            <div className="mt-6">
              <span className="font-semibold">The cBridge Fee</span> is for
              transferring from{' '}
              {originChain.name === 'ethereum' ? 'Ethereum' : 'Manta Pacific'}{' '}
              to Moonbeam via Celer network.
            </div>
          </>
        )}
      </div>
    );

    const gasFeeData = [
      {
        name: (
          <div className="flex items-center gap-1">
            Bridge Fees
            <Tooltip
              visible={true}
              className="item"
              content={bridgeFeesTooltip}
              placement="right"
              visibleArrow={false}>
              <Icon name="Info" className="cursor-help" />
            </Tooltip>
          </div>
        ),
        value: bridgeFee ? `${bridgeFees} ${symbol}` : <DotLoader />
      },
      {
        name: (
          <div className="flex items-center gap-1">
            Network Fees
            <Tooltip
              className="item"
              content={
                <div
                  style={{ width: '300px', fontSize: '14px', color: 'white' }}>
                  <span className="font-semibold">The Network fee</span> covers
                  the gas cost for transfers on the Moonbeam network.
                </div>
              }
              placement="right"
              visibleArrow={false}>
              <Icon name="Info" className="cursor-help" />
            </Tooltip>
          </div>
        ),
        value: bridgeFee ? (
          <span>
            GLMR
            <span
              className="font-red-hat-mono text-right ml-1"
              style={{ color: 'rgb(41, 204, 185)' }}>
              (Paid by Manta)
            </span>
          </span>
        ) : (
          <DotLoader />
        )
      },
      {
        name: (
          <div className="flex items-center gap-1">
            Estimate Receive
            <Tooltip
              className="item"
              effect="dark"
              content={
                <div style={{ fontSize: '14px', color: 'white' }}>
                  The estimated amount you will receive.
                </div>
              }
              placement="right"
              visibleArrow={false}>
              <Icon name="Info" className="cursor-help" />
            </Tooltip>
          </div>
        ),
        value: bridgeFee ? `${estimateReceive} ${symbol}` : <DotLoader />
      }
    ];

    setGasFee(gasFeeData);
  }, [bridgeFee, originGasFee]);

  return (
    <div className="flex flex-col gap-4 mb-10">
      {gasFee.map((item, index) => {
        return (
          <div
            key={index}
            className="px-2 text-default text-sm flex flex-row justify-between">
            <div>{item.name}</div>
            <div className="flex flex-col">
              <div className="font-red-hat-mono">{item.value}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TransferFeeDisplay;
