// Wallet names as recognized by @talisman/connect-wallets or manta-extension-connect library
const WALLET_NAME = {
  TALISMAN: 'talisman',
  SUBWALLET: 'subwallet-js',
  POLKADOT: 'polkadot-js',
  METAMASK: 'metamask',
  MANTA: 'manta-wallet-js',
};

export enum WalletErrorCode {
  rejectedNum = 4001,
  rejectedStr = 'ACTION_REJECTED',
  locked = 500,
  notAuth = 4100,
  alreadyClaimed = -32603,
  polkadotReject = 'Cancelled',
  substrateReject = 'Rejected by user'
}

export const REJECT_INFO = 'User reject this action';

export default WALLET_NAME;
