// @ts-nocheck
const SEND_ACTIONS = {
  TOGGLE_SENDER_ACCOUNT_IS_PRIVATE: 'TOGGLE_SENDER_ACCOUNT_IS_PRIVATE',
  TOGGLE_RECEIVER_ACCOUNT_IS_PRIVATE: 'TOGGLE_RECEIVER_ACCOUNT_IS_PRIVATE',
  SWAP_SENDER_AND_RECEIVER_ACCOUNTS_ARE_PRIVATE: 'SWAP_SENDER_AND_RECEIVER_ACCOUNTS_ARE_PRIVATE',
  SET_SELECTED_ASSET_TYPE: 'SET_SELECTED_ASSET_TYPE',
  SET_SENDER_ASSET_CURRENT_BALANCE: 'SET_SENDER_ASSET_CURRENT_BALANCE',
  SET_SENDER_ASSET_TARGET_BALANCE: 'SET_SENDER_ASSET_TARGET_BALANCE',
  SET_SENDER_NATIVE_TOKEN_PUBLIC_BALANCE: 'SET_SENDER_NATIVE_TOKEN_PUBLIC_BALANCE',
  SET_SENDER_PRIVATE_ADDRESS: 'SET_SENDER_PRIVATE_ADDRESS',
  SET_SENDER_PUBLIC_ACCOUNT: 'SET_SENDER_PUBLIC_ACCOUNT',
  SET_RECEIVER: 'SET_RECEIVER',
  SET_RECEIVER_CURRENT_BALANCE: 'SET_RECEIVER_CURRENT_BALANCE',
  SET_FEE_ESTIMATE: 'SET_FEE_ESTIMATE'
};

export default SEND_ACTIONS;
