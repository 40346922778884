// @ts-nocheck
import classNames from 'classnames';
import Icon from 'components/Icon';
import { useTxStatus } from 'contexts/txStatusContext';
import { Tooltip } from 'element-react';
import PropTypes from 'prop-types';
import { isMigrationExpired } from 'utils/display/getMigrationExpireTime';
import { useMigrationBurn } from '../MigrationBurnContext';
import { useMigrationHistory } from '../MigrationHistoryContext';
import BalanceDisplay from './BalanceDisplay';

const BalanceInput = ({
  onChangeAmountInput,
  inputValue,
  onClickMax,
  balanceText,
  shouldShowLoader
}) => {
  const isExpired = isMigrationExpired();
  const { claimStep, claimEVMToken } = useMigrationHistory();
  const { txStatus } = useTxStatus();
  const disabled =
    claimStep || (txStatus && !txStatus?.isFailed()) || isExpired;

  return (
    <div
      className={classNames(
        'flex flex-col w-full rounded-lg mb-6 content-around justify-center',
        { disabled: disabled }
      )}
      style={{
        height: '93px',
        padding: '16px',
        borderRadius: '16px',
        background: 'rgba(32, 30, 30, 0.05)'
      }}>
      <div className="flex flex-col justify-items-center">
        <input
          id="amountInput"
          autoComplete="off"
          onChange={(e) => onChangeAmountInput(e.target.value)}
          className={classNames(
            'w-4/5 absolute left-4 bottom-7 py-2 text-32px text-migration-secondary text-opacity-80 dark:text-migration-secondary dark:text-opacity-80 text-default dark:text-default bg-white bg-opacity-0 outline-none rounded-lg',
            { disabled: disabled }
          )}
          value={inputValue}
          placeholder={'0.00'}
          disabled={disabled}
        />
        <MaxButton
          id="maxAmount"
          isDisabled={disabled}
          onClickMax={onClickMax}
        />
      </div>
      <BalanceDisplay
        balance={balanceText}
        className="text-sm text-default mt-2.5 mr-4 absolute right-0 bottom-3 gap-1"
        loader={shouldShowLoader}
      />
    </div>
  );
};

BalanceInput.propTypes = {
  onChangeAmountInput: PropTypes.func,
  inputValue: PropTypes.string,
  onClickMax: PropTypes.func,
  balanceText: PropTypes.string,
  shouldShowLoader: PropTypes.bool
};

const MaxButton = ({ onClickMax, isDisabled }) => {
  const { feeEstimate } = useMigrationBurn();
  const feeText = feeEstimate?.toString() || '-';
  const onClick = () => {
    !isDisabled && onClickMax();
  };
  return (
    <span
      onClick={onClick}
      className={classNames(
        'cursor-pointer',
        'text-center rounded-lg unselectable-text absolute left-4 bottom-3 flex items-center  text-green-light',
        { disabled: isDisabled }
      )}
      style={{
        fontSize: '14px',
        fontWeight: '400',
        color: '#FF5E00'
      }}>
      Select Max
      <Tooltip
        visible={true}
        className="item"
        content={
          <div
            style={{
              width: '314px',
              fontSize: '12px',
              color: 'white'
            }}>
            {`Max Migration Amount = Total Balance - 0.5 KMA (for account activation)- ${feeText} KMA (for transaction fee)`}
          </div>
        }
        placement="right"
        visibleArrow={false}>
        <Icon
          name="InfoOutline"
          className="ml-2 cursor-help"
          style={{ width: '15px', height: '15px' }}
        />
      </Tooltip>
    </span>
  );
};

MaxButton.propTypes = {
  onClickMax: PropTypes.func,
  disabled: PropTypes.bool
};

export default BalanceInput;
