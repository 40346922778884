// @ts-nocheck
import store from 'store';

const HAS_AUTH_TO_CONNECT_EVM_KEY = 'hasAuthToConnectEVM';
const AUTHED_WALLET_LIST = 'hasAuthToConnectWallet';
const HAS_AUTH_TO_CONNECT_EVM_WALLET_NAME_KEY = 'hasAuthToConnectEVMWalletName';

export const setHasAuthToConnectEVMWalletStorage = (isAuthorized) => {
  store.set(HAS_AUTH_TO_CONNECT_EVM_KEY, isAuthorized);
};

export const getHasAuthToConnectEVMWalletStorage = () => {
  return store.get(HAS_AUTH_TO_CONNECT_EVM_KEY, false);
};

export const setHasAuthToConnectEVMWalletNameStorage = (walletName: string) => {
  store.set(HAS_AUTH_TO_CONNECT_EVM_WALLET_NAME_KEY, walletName);
};
export const getHasAuthToConnectEVMWalletNameStorage = () => {
  return store.get(HAS_AUTH_TO_CONNECT_EVM_WALLET_NAME_KEY);
};

export const setAuthedWalletListStorage = (walletNames) => {
  store.set(AUTHED_WALLET_LIST, walletNames);
};

export const getAuthedWalletListStorage = () => {
  return store.get(AUTHED_WALLET_LIST, []);
};
