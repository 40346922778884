// @ts-nocheck
import Icon, { IconName } from 'components/Icon';
import { useEffect, useState } from 'react';
import { getCaptcha } from 'utils/api/evmBridgeFaucet';
import { useMetamask } from 'contexts/metamaskContext';
import Chain from 'types/Chain';
import { useConfig } from 'contexts/configContext';
import { useBridgeData } from '../BridgeContext/BridgeDataContext';

const failedColor = '#F9413E';
const successColor = '#2EE9A5';

const borderStyle = {
  borderBottomWidth: '1px',
  borderColor: 'rgba(255,255,255,0.2)'
};

type Step = {
  index: number;
  title: string;
  subtitle: string;
  status: number;
};

type ErrMsgObj = {
  index: number;
  errMsg: string;
};

// Step steps
const StepStatus = ({
  steps,
  ethAddress,
  captcha,
  setCaptcha,
  currentButtonIndex,
  errMsgObj,
  isEthereumToManta
}: {
  steps: Array<Step>;
  ethAddress: string;
  captcha: string;
  setCaptcha: React.Dispatch<React.SetStateAction<string>>;
  currentButtonIndex: number;
  errMsgObj: ErrMsgObj;
  isEthereumToManta: boolean;
}) => {
  const [captchaImg, setCaptchaImg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const { provider } = useMetamask();
  const config = useConfig();
  const { destinationChain } = useBridgeData();
  const fetch = async () => {
    if (!ethAddress) return;
    const captchaRes = await getCaptcha(ethAddress);
    setCaptchaImg(captchaRes.data);
  };

  const handleInputChange = (e) => {
    setErrMsg('');
    setCaptcha(e.target.value);
  };

  const addMANTAToMetamask = async () => {
    const chainName =
      currentButtonIndex === 5
        ? 'Moonbeam'
        : destinationChain.name === 'ethereum'
        ? 'Ethereum'
        : 'MantaPacific';
    if (provider?.chainId !== Chain[chainName](config).ethMetadata.chainId) {
      // switch user's metamask to ${chainName} network
      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Chain[chainName](config).ethMetadata.chainId }]
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await provider.request({
              method: 'wallet_addEthereumChain',
              params: [Chain[chainName](config).ethMetadata]
            });
          } catch (addError) {
            // handle "add" error
            console.log('addError', addError.message);
            return;
          }
        }
        // handle other "switch" errors
        console.log('switchError', switchError.message);
        return;
      }
    }

    provider
      .request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address:
              chainName === 'Moonbeam'
                ? config.MantaContractOnMoonbeam
                : destinationChain.name === 'ethereum'
                ? config.MantaContractOnEthereum
                : config.MantaContractOnMantaPacific,
            symbol: chainName === 'Moonbeam' ? 'xcMANTA' : 'MANTA',
            decimals: 18,
            image: ''
          }
        }
      })
      .then((success) => {
        if (success) {
          console.log('MANTA successfully added to wallet!');
        } else {
          throw new Error('Something went wrong.');
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    fetch();
  }, [ethAddress]);

  useEffect(() => {
    if (errMsgObj.errMsg !== undefined) {
      setErrMsg(errMsgObj.errMsg);
    }
    if (errMsgObj.index === 1) {
      fetch();
    }
  }, [errMsgObj]);

  return (
    <div className="flex items-center justify-center mt-8">
      <div className="flex flex-col text-default">
        {steps.map((item, index) => {
          // status, 0 = default, 1 = success, 2 = failed, 3 = pending
          return (
            <div
              key={index}
              className="basis-1/3 flex flex-row justify-content py-4"
              style={index < 2 ? borderStyle : {}}>
              <StepNumberIndicator currentStep={item} />

              <div className="pl-10">
                <p className="text-sm	font-semibold">{item.title}</p>

                <p
                  className="text-sm text-desc text-opacity-60"
                  style={item.status === 2 ? { color: failedColor } : {}}>
                  {item.status === 1 ? item.success : item.subtitle}
                </p>
                {((index === 0 && currentButtonIndex === 5) ||
                  (index === 2 &&
                    currentButtonIndex === 15 &&
                    !isEthereumToManta)) && (
                  <button
                    className="mt-2 text-green-light text-sm"
                    onClick={addMANTAToMetamask}>
                    Add MANTA to Metamask
                  </button>
                )}
                {index === 1 && currentButtonIndex === 5 && (
                  <div className="mt-3">
                    <div
                      className="cursor-pointer h-10 rounded-lg inline-block overflow-hidden"
                      onClick={fetch}
                      dangerouslySetInnerHTML={{ __html: captchaImg }}
                    />
                    <div className="block mb-2 text-sm font-semibold">
                      To continue, type the characters above:
                    </div>
                    <input
                      className="placeholder-gray-500 rounded bg-light-blue w-44 h-10 px-3"
                      maxLength="4"
                      placeholder="please type the captcha"
                      value={captcha}
                      onChange={handleInputChange}
                    />
                  </div>
                )}
                {index === errMsgObj.index && (
                  <div
                    className="w-96"
                    style={{
                      color: errMsgObj.errMsgIsWarning ? '#29CCB9' : '#f9413e',
                      marginTop: '4px',
                      wordWrap: 'break-word'
                    }}>
                    {errMsg}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// Step number indicator
const StepNumberIndicator = ({ currentStep }: { currentStep: Step }) => {
  const containerStyle =
    'w-8 h-8 flex items-center justify-center rounded-full bg-white';
  const successStyle = { backgroundColor: successColor, border: 'none' };
  const failedStyle = { backgroundColor: failedColor, border: 'none' };
  const iconStyle = 'w-5 h-5 rounded-full';

  // status, 0 = default, 1 = success, 2 = failed, 3 = pending
  if (currentStep.status === 1) {
    // return success number indicator
    return (
      <div className={containerStyle} style={successStyle}>
        <Icon className={iconStyle} name={'successIcon' as IconName} />
      </div>
    );
  } else if (currentStep.status === 2) {
    // return failed number indicator
    return (
      <div className={containerStyle} style={failedStyle}>
        <Icon className={iconStyle} name={'failedIcon' as IconName} />
      </div>
    );
  } else if (currentStep.status === 3) {
    // return pending number indicator
    return (
      <div className={[containerStyle, 'bg-white']}>
        <div className="gradient-button rounded-full w-8 h-8 items-center flex justify-center">
          <div
            style={{ borderTopColor: 'transparent' }}
            className="w-4 h-4 border-4 border-white border-dotted rounded-full animate-spin"
          />
        </div>
      </div>
    );
  }
  // return default number indicator
  return (
    <div className={[containerStyle, 'border-black border-2 rounded-full']}>
      <p className="text-xl text-nav">{currentStep.index}</p>
    </div>
  );
};

export default StepStatus;
