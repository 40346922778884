// @ts-nocheck
import Icon, { IconName } from 'components/Icon';

type Chain = {
  name: string;
  logo: string;
  status: number;
};

// Source chain to destination chain
const ChainStatus = ({ chainList }: { chainList: Array }) => {
  const successChainName = {
    backgroundColor: 'rgba(46, 233, 165, 0.05)',
    borderColor: 'rgba(46, 233, 165, 0.80)'
  };

  const failedChainName = {
    backgroundColor: 'rgba(249, 65, 62, 0.2)',
    borderColor: 'rgba(249, 65, 62, 0.4)'
  };
  return (
    <div className="flex flex-row	text-white pt-4">
      {chainList.map((item: Chain, index: number) => {
        // status, 0 = default, 1 = success, 2 = failed, 3 = pending
        const status = item.status;
        let style = {};
        if (status === 2) {
          style = failedChainName;
        } else if (index === 0 || status === 1) {
          style = successChainName;
        } else if (index === 1 && chainList[0].status === 1) {
          style = successChainName;
        }
        return (
          <div key={index} className="flex-1 flex justify-center items-center">
            <div
              className="w-36 py-2 flex flex-col items-center border border-dark-blue bg-light-blue border-opacity-40 rounded-lg text-default"
              style={style}>
              <div className="bg-line rounded-full p-0.5 mb-2">
                <Icon
                  className="w-6 h-6 rounded-full"
                  name={item.logo as IconName}
                />
              </div>

              <p className="text-base	pl-2">{item.name}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ChainStatus;
