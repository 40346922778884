import AccountSelectMenu from '../Accounts/AccountSelectMenu';
import Icon from '../Icon';
import Menu from '../Menu/DotMenu';

export const CalamariBare = ({ withWallet }: { withWallet?: boolean }) => {
  return (
    <div className="h-20 py-4 px-88px flex justify-center sm:justify-between items-center z-50 bg-none">
      <div className="flex items-center">
        <Icon name={'calamariLogo'} className="w-8 h-8" />
        <div className="ml-4 text-xl font-red-hat-mono"> Calamari Network</div>
      </div>

      {withWallet ? (
        <div className="h-10 gap-4 flex flex-wrap justify-end items-center">
          <AccountSelectMenu />
          <Menu />
        </div>
      ) : null}
    </div>
  );
};

export default CalamariBare;
