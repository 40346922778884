// @ts-nocheck
import React, { useEffect, useState } from 'react';
import DotLoader from 'components/Loaders/DotLoader';
import { formatHoursMinutes } from 'utils/time/timeString';
import { useConfig } from 'contexts/configContext';
import { useWallet } from 'contexts/walletContext';
import Button from 'components/Button';
import { useWalletSignature } from 'hooks';
import { getDisplayAddress } from 'utils/display/getDisplayAddress';
import { useStakerBindContract } from 'pages/StakePage/abi/stakerBind/index';
import { generateBindSignature } from 'utils/api/staker';
import { useMetamask } from 'contexts/metamaskContext';
import CopyPasteIcon from 'components/CopyPasteIcon';
import { showError } from 'utils/ui/Notifications';
import classNames from 'classnames';
import { ethers } from 'ethers';
import { showWarning } from 'utils/ui/Notifications';
import LoadingIcon from 'components/LoadingIcon';
import { ConnectWalletButton } from 'components/Accounts/ConnectWallet';
import { useStakeData } from './StakeContext/StakeDataContext';

const SwitchNetworkButton = () => {
  const { isSwitchingNetwork, switchNetwork } = useMetamask();
  return (
    <Button
      onClick={() => switchNetwork('MantaPacific')}
      className="gradient-button text-white flex items-center justify-center mt-4">
      Switch Network to Manta Pacific
      {isSwitchingNetwork && <LoadingIcon className="ml-2" />}
    </Button>
  );
};

const BindEVMAddress = () => {
  const { selectedAccount: externalAccount, selectedWallet } = useWallet();
  const config = useConfig();
  const { provider, switchNetwork, chainId, ethAddress } = useMetamask();
  const { getWalletSignature } = useWalletSignature();
  const { readContract, writeContract } = useStakerBindContract();

  const [bondedAddress, setBondedAddress] = useState<string | null>(null);
  const [isBindBtnLoading, setIsBindBtnLoading] = useState(false);
  const isPacificNetwork = Number(chainId) === config.mantaPacificChainID;

  const getBondedAddress = async () => {
    if (!externalAccount?.address || !provider || !isPacificNetwork) {
      return;
    }
    try {
      const bondedAddress = await readContract(
        provider
      ).getRecordByAtlanticAddress(externalAccount?.address);
      setBondedAddress(Number(bondedAddress) === 0 ? null : bondedAddress);
    } catch (e) {
      console.error('getRecordByAtlanticAddress', e);
    }
  };

  useEffect(() => {
    getBondedAddress();
  }, [externalAccount?.address, provider, isPacificNetwork]);

  const handleBindOrUnbind = async (isBind: 1 | 0) => {
    setIsBindBtnLoading(true);

    try {
      // switch network to pacific network
      await switchNetwork('MantaPacific');

      const walletSigRes = await getWalletSignature();
      const { data } = await generateBindSignature({
        bindAddress: isBind,
        ...walletSigRes
      });

      if (data?.code === 0) {
        const { atlanticAddress, pacificAddress, nonce, v, r, s } = data.data;

        // check gas
        const gasAmount = await writeContract(
          provider
        ).estimateGas.bindPacificAddress(
          atlanticAddress,
          pacificAddress,
          nonce,
          v,
          r,
          s
        );
        const _provider = new ethers.providers.Web3Provider(provider);
        const gasPrice = await _provider.getGasPrice();
        const gasFeeInWei = gasPrice.mul(gasAmount);
        const gasFeeInETH = ethers.utils.formatEther(gasFeeInWei);
        const balance = await _provider.getBalance(ethAddress);
        const balanceInETH = ethers.utils.formatEther(balance);
        if (balanceInETH < gasFeeInETH) {
          showWarning('Insufficient ETH for gas fee');
          return;
        }

        const waitUserConfirm = await writeContract(
          provider
        ).bindPacificAddress(atlanticAddress, pacificAddress, nonce, v, r, s);
        await waitUserConfirm.wait();
        await getBondedAddress();
      } else if (data?.code === -3) {
        showError(data?.message);
      }
    } catch (e) {
      console.error('--handleBindOrUnbind--', e);
    } finally {
      setIsBindBtnLoading(false);
    }
  };

  const onClickBind = () => handleBindOrUnbind(1);
  const onClickUnbind = () => handleBindOrUnbind(0);

  const renderButton = () => {
    if (!externalAccount?.address) {
      return (
        <ConnectWalletButton
          text="Connect Polkadot Wallet"
          className={
            'gradient-button text-white font-red-hat-text text-sm h-10 w-44 cursor-pointer rounded-lg mt-4'
          }
        />
      );
    }
    if (!ethAddress) {
      return (
        <ConnectWalletButton
          text="Connect EVM Wallet"
          className={
            'gradient-button text-white font-red-hat-text text-sm h-10 w-44 cursor-pointer rounded-lg mt-4'
          }
        />
      );
    }
    if (!isPacificNetwork) {
      return <SwitchNetworkButton />;
    }

    return bondedAddress ? (
      <div className="flex mt-4 justify-between items-center">
        <p className="mr-4">{getDisplayAddress(bondedAddress)}</p>
        <CopyPasteIcon
          textToCopy={bondedAddress}
          iconName="copySquareWhite"
          iconClassName="bg-default mr-12 h-6 w-6"
        />
        <Button
          className="btn-secondary text-white flex items-center justify-center"
          onClick={onClickUnbind}
          disabled={isBindBtnLoading}>
          {isBindBtnLoading ? <div>In Progress...</div> : 'Unbind'}
        </Button>
      </div>
    ) : (
      <Button
        className={classNames(
          'gradient-button text-white flex items-center justify-center mt-4',
          {
            'opacity-60': !selectedWallet
          }
        )}
        style={{ width: '300px' }}
        onClick={onClickBind}
        disabled={isBindBtnLoading || !selectedWallet}>
        {isBindBtnLoading ? (
          <div>
            In Progress <DotLoader />
          </div>
        ) : (
          'Bind'
        )}
      </Button>
    );
  };

  return (
    <div className="flex flex-grow flex-col p-6 bg-secondary rounded-lg relative z-20 flex-shrink-0">
      <h2 className="text-default font-semibold text-base font-red-hat-text text-left">
        Bind your EVM Address
      </h2>
      {renderButton()}
    </div>
  );
};

const AccountDisplay = () => {
  const config = useConfig();
  const {
    userTotalBalance,
    userAvailableBalance,
    userStakedBalance,
    userTotalRecentRewards,
    secondsSinceReward
  } = useStakeData();

  const { selectedAccount: externalAccount } = useWallet();

  const getBalanceDisplayString = (balance) => {
    if (!externalAccount) {
      return '';
    } else if (!balance) {
      return <DotLoader />;
    } else {
      return balance.toDisplayString(2);
    }
  };

  const blockExplorerRewardsLink = `${config.SUBSCAN_URL}/nominator/${externalAccount?.address}?tab=reward`;
  const timeSinceRewardDisplayString = secondsSinceReward
    ? `Last updated: ${formatHoursMinutes(secondsSinceReward)} ago`
    : '';
  const totalBalanceDisplayString = getBalanceDisplayString(userTotalBalance);
  const avialableBalanceDisplayString =
    getBalanceDisplayString(userAvailableBalance);
  const stakedBalanceDisplayString = getBalanceDisplayString(userStakedBalance);
  const userTotalRecentRewardsDisplayString = getBalanceDisplayString(
    userTotalRecentRewards
  );

  const onClickStartStaking = () => {
    const element = document.getElementById('collatorsTable');
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  };

  return (
    <div className="text-center font-red-hat-text max-w-1280 w-full mx-auto">
      <div className="inline-flex xl:flex-nowrap justify-start flex-wrap-reverse gap-4 w-full">
        <div className="p-6 bg-secondary flex-grow rounded-lg">
          <div className="-mt-2 relative z-20 flex justify-between items-center ">
            <div className="mt-4">
              <h2 className="text-default text-center font-semibold text-base font-red-hat-text">
                Total Balance
              </h2>
              <h1 className="text-default text-3xl text-center mt-4">
                {totalBalanceDisplayString}
              </h1>
              <button
                onClick={onClickStartStaking}
                className={
                  'mt-8 p-3 cursor-pointer font-semibold text-sm unselectable-text text-center rounded-lg gradient-button text-white w-full'
                }>
                Start staking
              </button>
            </div>
            <div className="flex justify-end gap-5">
              <div className=" w-52 h-52 rounded-lg bg-white text-center pt-12">
                <h2 className="text-default font-semibold text-base font-red-hat-text">
                  Available Balance
                </h2>
                <h1 className="text-default text-2xl mt-4 font-red-hat-text">
                  {avialableBalanceDisplayString}
                </h1>
              </div>
              <div className=" w-52 h-52 rounded-lg bg-white text-center pt-12">
                <h2 className="text-default font-semibold text-base font-red-hat-text">
                  Total Staked
                </h2>
                <h1 className="text-default text-2xl mt-4 font-red-hat-text">
                  {stakedBalanceDisplayString}
                </h1>
              </div>
              <div className=" w-52 h-52 rounded-lg bg-white text-center pt-12">
                <h2 className="text-default font-semibold text-base font-red-hat-text">
                  Rewards Last Round
                </h2>
                <h1 className="text-default text-2xl mt-4 font-red-hat-text">
                  {userTotalRecentRewardsDisplayString}
                </h1>
                <h3 className="font-red-hat-text text-default text-sm mt-3">
                  {timeSinceRewardDisplayString}
                </h3>
                {userTotalRecentRewards && (
                  <div className="mt-3">
                    <a
                      href={blockExplorerRewardsLink}
                      className="gradient-text text-sm"
                      target="_blank"
                      rel="noreferrer">
                      More
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          <p className="text-xss mt-4 text-left">
            <span className="font-semibold">Please note: </span>Staking rewards
            will be sent to your address every 6 hours. Unstaking process will
            take a period of 7 days to complete.
          </p>
        </div>

        <div>
          <BindEVMAddress />
          <div className="mt-4 max-w-sm flex flex-grow flex-col flex-shrink-0 p-6 bg-secondary rounded-lg relative">
            <div className="text-left">
              <h2 className="text-default font-semibold text-base font-red-hat-text">
                Resources
              </h2>

              {config.NETWORK_NAME === 'Manta' && (
                <div className="mt-4">
                  <a
                    href="https://docs.manta.network/docs/manta-atlantic/Collation/Overview"
                    className="gradient-text text-sm"
                    target="_blank"
                    rel="noreferrer">
                    Collator Guidance
                  </a>
                </div>
              )}

              <div className="mt-4">
                <a
                  href={`https://${config.NETWORK_NAME.toLowerCase()}.subscan.io`}
                  className="gradient-text text-sm"
                  target="_blank"
                  rel="noreferrer">
                  {`${config.NETWORK_NAME} block explorer`}
                </a>
              </div>
              {config.NETWORK_NAME === 'Calamari' && (
                <>
                  <div className="mt-4">
                    <a
                      href="https://stakekma.com"
                      className="gradient-text text-sm"
                      target="_blank"
                      rel="noreferrer">
                      StakeKMA collator dashboard
                    </a>
                  </div>
                  <div className="mt-4">
                    <a
                      href="https://sparta.calamari.systems"
                      className="gradient-text text-sm"
                      target="_blank"
                      rel="noreferrer">
                      Calamari collator dashboard
                    </a>
                  </div>
                  <div className="mt-4">
                    <a
                      href="https://app.web3go.xyz/#/CalamariStaking"
                      className="gradient-text text-sm"
                      target="_blank"
                      rel="noreferrer">
                      web3go Calamari staking
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDisplay;
