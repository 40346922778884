import axios, { AxiosError, AxiosResponse } from 'axios';
import { useConfig } from 'contexts/configContext';
import { useMetamask } from 'contexts/metamaskContext';
import { useTxStatus } from 'contexts/txStatusContext';
import { useWallet } from 'contexts/walletContext';
import { ethers } from 'ethers';
import pLimit from 'p-limit';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import TxStatus from 'types/TxStatus';
import { useKMAMigrateContract } from '../abi';
import { useMigrationBurn } from '../MigrationBurnContext';
import { showError, showSuccess } from '../MigrationNotification';

type MigrationHistoryContextProps = {
  children: ReactNode;
};

export interface HistoryRecordItem {
  tx_hash: string;
  timestamp: number;
  calamari_address: string;
  evm_address: string;
  amount: number;
  status: number;
  claimed?: boolean;
}

type MigrationHistoryContextType = {
  claimStep: boolean;
  setClaimStep: (value: boolean) => void;
  historyRecord: HistoryRecordItem[];
  getHistoryRecord: () => Promise<HistoryRecordItem[]>;
  patchHistoryRecord: (
    _ethAddress: string,
    _historyRecord: HistoryRecordItem[]
  ) => Promise<HistoryRecordItem[]>;
  claimEVMToken: (
    _currentTx?: HistoryRecordItem,
    skipTxStatus?: boolean
  ) => Promise<void>;
  claimEVMTokenStatus: 'true' | 'false' | '';
};

const MigrationHistoryContext = createContext<MigrationHistoryContextType>({
  claimStep: false,
  setClaimStep: () => {},
  historyRecord: [],
  getHistoryRecord: async () => [],
  claimEVMToken: async () => undefined,
  patchHistoryRecord: async () => [],
  claimEVMTokenStatus: ''
});

export const MigrationHistoryContextProvider = (
  props: MigrationHistoryContextProps
) => {
  const config = useConfig();
  const { setTxStatus } = useTxStatus();
  const { selectedAccount: externalAccount } = useWallet();
  const publicAddress = externalAccount?.address || '';
  const { writeContract, readContract } = useKMAMigrateContract();
  const { ethAddress, provider } = useMetamask();
  const { finalTx, setFinalTxRef } = useMigrationBurn();
  const finalTxHash = finalTx?.txHash?.toHex();
  const finalTxFinalized = finalTx?.status?.isFinalized;
  const [claimStep, setClaimStep] = useState<boolean>(false); // 是否为claim步骤
  const [claimEVMTokenStatus, setClaimEVMTokenStatus] = useState<
    'true' | 'false' | ''
  >('');
  const [historyRecord, setHistoryRecord] = useState<HistoryRecordItem[]>([]);
  const migrationApiEndpoint = config.IS_TESTNET
    ? config.MIGRATION_API_ENDPOINT_TESTNET
    : config.MIGRATION_API_ENDPOINT;

  const claimEVMToken = async (
    _currentTx?: HistoryRecordItem,
    skipTxStatus = false
  ) => {
    try {
      const historyCurrentTx = historyRecord.filter(
        (item) => item.tx_hash?.toLowerCase() === finalTxHash?.toLowerCase()
      )[0];
      const currentTx = _currentTx || historyCurrentTx;
      if (!currentTx) {
        return;
      }
      setTxStatus(TxStatus.processing());
      // Signature request currentTx
      const paramTx = {
        kmaAddress: currentTx?.calamari_address,
        txHash: currentTx?.tx_hash,
        amount: currentTx?.amount?.toString(),
        evmAddress: currentTx?.evm_address
      };
      const queryParams = new URLSearchParams(paramTx).toString();
      const response: void | AxiosResponse = await axios.post(
        `${migrationApiEndpoint}/kma_mint/generate_signature?${queryParams}`
      );
      const { r, s, v } = response?.data?.data || {};
      if (!r || !s || !v) {
        setTxStatus(TxStatus.failed('Failed to get signature'));
        showError('Failed to get signature');
        return;
      }

      // Claim
      const amountInWei = ethers.utils.parseUnits(
        currentTx?.amount.toString(),
        18
      );
      const tx = await writeContract(provider)?.claimToken(
        currentTx?.evm_address,
        currentTx?.tx_hash,
        amountInWei.toBigInt(),
        v,
        r,
        s
      );
      const receipt = await tx.wait();
      if (receipt.status === 1) {
        const subscanBaseUrl = config.IS_DEV
          ? config.MANTA_TESTNET_SUBSCAN_URL
          : config.MANTA_PACIFIC_SUBSCAN_URL;
        const subscanUrl = `${subscanBaseUrl}/tx/${tx?.hash}`;
        showSuccess(subscanUrl);
        setTxStatus(null);
        const txHash = finalTxHash || _currentTx?.tx_hash;
        const finalHistoryRecord = historyRecord.map((item) => {
          if (item.tx_hash?.toLowerCase() === txHash?.toLowerCase()) {
            return { ...item, claimed: true };
          }
          return item;
        });
        setHistoryRecord(finalHistoryRecord);
        setFinalTxRef(null);
        setClaimStep(false);
        setClaimEVMTokenStatus('true');
      } else {
        setTxStatus(TxStatus.failed('Claim Token failed'));
        showError('Claim Token failed');
        setClaimEVMTokenStatus('false');
      }
    } catch (error: any) {
      const errorMsg = `${error?.reason || error?.message}` || 'Claim Failed';
      showError(errorMsg);
      skipTxStatus ? setTxStatus(null) : setTxStatus(TxStatus.failed(errorMsg));
      if (error?.code !== 'ACTION_REJECTED') {
        setClaimEVMTokenStatus('false');
      }
      console.log('claimMigrationToken error', error);
    }
  };

  const patchHistoryRecord = useCallback(
    async (
      _ethAddress: string,
      _historyRecord: HistoryRecordItem[]
    ): Promise<HistoryRecordItem[]> => {
      // const startTime = performance.now();
      if (_historyRecord?.length) {
        const txHistory = await readContract(provider).txHistory;
        const limit = pLimit(6); // 同时最多处理 6 个请求 firefox

        const historyFinal = await Promise.all(
          _historyRecord.map(({ evm_address, tx_hash, ...rest }) =>
            limit(async () => {
              const claimed = await txHistory(tx_hash);
              return {
                claimed,
                evm_address,
                tx_hash,
                ...rest
              };
            })
          )
        );
        // const endTime = performance.now();
        // const executionTime = (endTime - startTime) / 1000;
        // console.log(
        //   `patchHistoryRecord 执行时间：${executionTime.toFixed(
        //     3
        //   )} 秒. ${_ethAddress}`
        // );
        setHistoryRecord(historyFinal);
        return historyFinal;
      }
      return [];
    },
    [readContract, provider, setHistoryRecord, ethAddress, historyRecord]
  );

  const checkClaimStep = (history: HistoryRecordItem[]) => {
    if (history?.length) {
      const index = history.findIndex(
        (d) => d?.tx_hash?.toLowerCase() === finalTxHash?.toLowerCase()
      );
      if (index > -1) {
        setClaimStep(true);
        setTxStatus(null);
      }
    }
  };

  const getHistoryRecord = async (): Promise<HistoryRecordItem[]> => {
    try {
      const response: void | AxiosResponse<{ data: HistoryRecordItem[] }> =
        await axios
          .get(`${migrationApiEndpoint}/kma_migration/get_migration_history`, {
            params: {
              kmaAddress: publicAddress
            },
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .catch((error: AxiosError) => {
            console.log(error);
          });
      const data = response?.data.data;

      const historyFinal =
        data && data.length > 0
          ? provider
            ? await patchHistoryRecord(ethAddress, data)
            : data
          : [];
      setHistoryRecord(historyFinal);
      return historyFinal;
    } catch (error: any) {
      showError(`${error?.reason || error?.message}` || 'Get History Failed');
      console.log(error);
      return [];
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const asyncGetHistoryRecord = async () => {
        if (!finalTxFinalized) return;
        if (finalTxFinalized && publicAddress && !claimStep) {
          const history = await getHistoryRecord();
          checkClaimStep(history);
        }
      };
      asyncGetHistoryRecord();
    }, 15000);
    return () => clearInterval(interval);
  }, [finalTxFinalized, publicAddress, ethAddress, claimStep]);

  return (
    <MigrationHistoryContext.Provider
      value={{
        claimStep,
        setClaimStep,
        claimEVMTokenStatus,
        historyRecord,
        getHistoryRecord,
        patchHistoryRecord,
        claimEVMToken
      }}>
      {props.children}
    </MigrationHistoryContext.Provider>
  );
};

export const useMigrationHistory = () =>
  useContext<MigrationHistoryContextType>(MigrationHistoryContext);
