// @ts-nocheck
import BN from 'bn.js';
import classNames from 'classnames';
import { ConnectWalletButton } from 'components/Accounts/ConnectWallet';
import { useMetamask } from 'contexts/metamaskContext';
import { API_STATE, useSubstrate } from 'contexts/substrateContext';
import { useTxStatus } from 'contexts/txStatusContext';
import { useWallet } from 'contexts/walletContext';
import { isMigrationPage } from 'utils/display/getIsMigrationPage';
import {
  isMigrationExpired,
  MIGRATION_ENDS_TEXT
} from 'utils/display/getMigrationExpireTime';
import { useMigrationBurn } from './MigrationBurnContext';
import { useMigrationHistory } from './MigrationHistoryContext';
import useMigrationBalanceText from './MigrationSendBalance/useBalanceText';

const InnerSendButton = ({ senderLoading }) => {
  const { burnTransfer } = useMigrationBurn();
  const { ethAddress } = useMetamask();
  const { txStatus } = useTxStatus();
  const { claimStep, claimEVMToken } = useMigrationHistory();
  const disabled =
    txStatus?.isFinalized() || txStatus?.isProcessing() || senderLoading;
  let buttonLabel = 'Confirm Your Migration';
  if (txStatus?.isFailed()) buttonLabel = 'Transaction Failed,Please try again';
  if (txStatus?.isFinalized()) {
    buttonLabel = 'Checking Transaction, Claim After Completion';
  }
  if (claimStep) buttonLabel = 'Claim Your New KMA Now';
  if (txStatus?.isProcessing()) buttonLabel = 'Processing...';

  const onClickHandler = () => {
    if (!disabled) {
      claimStep ? claimEVMToken() : burnTransfer(ethAddress);
    }
  };

  return !disabled ? (
    <button
      id="sendButton"
      onClick={onClickHandler}
      className={classNames(
        'py-4 cursor-pointer unselectable-text',
        'text-center text-white rounded-lg migration-gradient-button w-full',
        { disabled: disabled }
      )}>
      {buttonLabel}
    </button>
  ) : (
    <div
      className={classNames(
        'flex items-center justify-center py-2 unselectable-text text-center text-white rounded-lg w-full migration-gradient-button filter opacity-60'
      )}>
      {buttonLabel}
    </div>
  );
};

const ValidationSendButton = () => {
  const { apiState } = useSubstrate();
  const { senderAssetTargetBalance, getMaxSendableBalance } =
    useMigrationBurn();
  const { txStatus } = useTxStatus();
  const { claimStep } = useMigrationHistory();
  const isExpired = isMigrationExpired();
  const apiIsDisconnected =
    apiState === API_STATE.ERROR || apiState === API_STATE.DISCONNECTED;
  const { shouldShowLoader: senderLoading } = useMigrationBalanceText();

  const convertToBN = (value: string | undefined): BN => {
    if (!value) return new BN(0);
    const [integerPart, fractionalPart = ''] = value.split('.');
    const paddedFractional = fractionalPart.padEnd(12, '0');
    return new BN(integerPart + paddedFractional);
  };

  let validationMsg = null;
  const maxSendableBalance = getMaxSendableBalance();
  const maxSendableBN = convertToBN(maxSendableBalance?.toString());
  const targetBalanceBN = convertToBN(senderAssetTargetBalance?.toString());
  const isInsufficientBalance = maxSendableBN.lt(targetBalanceBN);
  const senderAssetTargetBalanceStr = senderAssetTargetBalance;

  if (apiIsDisconnected) {
    validationMsg = 'Connecting to network';
  } else if (isExpired) {
    validationMsg = MIGRATION_ENDS_TEXT;
  } else if (!txStatus && !claimStep) {
    if (targetBalanceBN.isZero() || targetBalanceBN.isNeg()) {
      validationMsg = 'Enter Amount';
    } else if (isInsufficientBalance) {
      validationMsg = 'Insufficient balance';
    }
  }

  const ValidationText = ({ validationMsg }) => {
    return (
      <div
        className={classNames(
          'flex items-center justify-center py-2 unselectable-text text-center text-white rounded-lg w-full migration-gradient-button filter opacity-60'
        )}>
        {validationMsg}
      </div>
    );
  };

  return (
    <>
      {validationMsg && <ValidationText validationMsg={validationMsg} />}
      {!validationMsg && <InnerSendButton senderLoading={senderLoading} />}
    </>
  );
};

const MigrationSendButton = () => {
  const { txStatus } = useTxStatus();
  const { ethAddress } = useMetamask();
  const { selectedAccount: externalAccount } = useWallet();
  const publicAddress = externalAccount?.address || '';

  if (isMigrationPage) {
    let connectText = '';
    if (!publicAddress) {
      connectText = 'Connect Polkadot Wallet';
    } else if (!ethAddress) {
      connectText = 'Connect EVM Wallet';
    }
    const shouldShowEVMWallet = !ethAddress && publicAddress;
    if (connectText) {
      return (
        <ConnectWalletButton
          text={connectText}
          shouldShowPolkadotWallet={!publicAddress}
          shouldShowEVMWallet={shouldShowEVMWallet}
          className={classNames(
            'migration-gradient-button py-4 unselectable-text text-center text-white rounded-xl w-full'
          )}
        />
      );
    }
  }

  return <ValidationSendButton />;
};

export default MigrationSendButton;
