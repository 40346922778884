// @ts-nocheck
import { REJECT_INFO, WalletErrorCode } from 'constants/WalletConstants';
import { useCallback } from 'react';
import { showError } from 'utils/ui/Notifications';
import { useWallet } from 'contexts/walletContext';
import { useMetamask } from 'contexts/metamaskContext';
import { ethers } from 'ethers';

const MSG =
  'Please sign to confirm whether to bind your Pacific address. Operations may include: Bind Manta Pacific Address, Unbind Manta Pacific Address.';

const useSignMessage = () => {
  const { provider } = useMetamask();

  async function signMessageAsync(message: string) {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(provider);
      const signer = ethersProvider.getSigner();
      const signature = await signer.signMessage(message);
      return signature;
    } catch (err) {
      return '';
      console.error('Error signing message: ', err);
    }
  }

  return { signMessageAsync };
};

export const useWalletSignature = () => {
  const { ethAddress, provider } = useMetamask();
  const { signMessageAsync } = useSignMessage();

  const { selectedAccount: externalAccount, selectedAccount } = useWallet();

  const getWalletSignature = useCallback(async () => {
    try {
      let ethSig = '';
      let dotSig = '';
      // if no eth address, request login
      if (!ethAddress) {
        await provider?.request({ method: 'eth_requestAccounts' });
      }
      ethSig = await signMessageAsync(MSG);

      if (selectedAccount) {
        dotSig = (
          await externalAccount?.signer?.signRaw({
            type: 'payload',
            data: MSG,
            address: externalAccount.address
          })
        ).signature;
      }

      return {
        pacificAddress: ethAddress,
        pacificSignature: ethSig,
        atlanticAddress: externalAccount?.address,
        atlanticSignature: dotSig
      };
    } catch (e: any) {
      if (
        e?.code === WalletErrorCode.rejectedNum ||
        e?.code === WalletErrorCode.rejectedStr ||
        e?.message === WalletErrorCode.polkadotReject ||
        e?.message === WalletErrorCode.substrateReject
      ) {
        showError(REJECT_INFO);
      }
      throw e;
    }
  }, [ethAddress, selectedAccount, externalAccount?.signer, signMessageAsync]);

  return { getWalletSignature };
};
