// @ts-nocheck
import { useTxStatus } from 'contexts/txStatusContext';
import { useMigrationBurn } from 'pages/MigrationPage/MigrationBurnContext';
import { useEffect, useState } from 'react';
import handleChangeBalanceInput from 'utils/validation/handleChangeBalanceInput';
import BalanceInput from './BalanceInput';
import useMigrationBalanceText from './useBalanceText';

const BalanceInputContainer = () => {
  const {
    senderAssetTargetBalance,
    setSenderAssetTargetBalance,
    senderAssetType,
    getMaxSendableBalance,
    finalTx
  } = useMigrationBurn();
  const [inputValue, setInputValue] = useState('');
  const { balanceText, shouldShowLoader } = useMigrationBalanceText();
  const { txStatus, setTxStatus } = useTxStatus();
  const isTxFailed = txStatus?.isFailed();
  const balanceRegex = /^(?!0\d)\d{1,20}(\.?\d{0,2})?$/;

  useEffect(() => {
    if (!finalTx) {
      setInputValue('');
      setSenderAssetTargetBalance(null);
    }
  }, [finalTx]);

  const onChangeSendAmountInput = (newInputValue) => {
    if (isTxFailed) setTxStatus(null);
    const validatedValue =
      newInputValue === '' || balanceRegex.test(newInputValue)
        ? newInputValue
        : inputValue;
    handleChangeBalanceInput({
      newInputString: validatedValue,
      prevInputString: inputValue,
      setInputString: setInputValue,
      setBalance: setSenderAssetTargetBalance,
      assetType: senderAssetType
    });
  };

  useEffect(() => {
    const truncateDecimalsOnChangeAssetType = () => {
      senderAssetTargetBalance &&
        onChangeSendAmountInput(senderAssetTargetBalance.toStringUnrounded());
    };
    truncateDecimalsOnChangeAssetType();
  }, [senderAssetType]);

  const onClickMax = () => {
    const maxSendableBalance = getMaxSendableBalance();
    if (maxSendableBalance) {
      onChangeSendAmountInput(maxSendableBalance.toString());
    }
  };

  return (
    <BalanceInput
      onChangeAmountInput={onChangeSendAmountInput}
      inputValue={inputValue}
      onClickMax={onClickMax}
      balanceText={balanceText}
      shouldShowLoader={shouldShowLoader}
    />
  );
};

export default BalanceInputContainer;
