// @ts-nocheck
import { EcosystemArr } from 'constants/EcosystemConstants';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useConfig } from 'contexts/configContext';

const EcosystemBlock = ({ item }) => {
  const { NETWORK_NAME } = useConfig();
  const networkUrlParam = `/${NETWORK_NAME.toLowerCase()}`;
  const EcosystemNameBlock = () => {
    return (
      <div className="flex flex-col w-full">
        <div className="text-base flex items-center gap-3 leading-5 w-full justify-between font-semibold">
          {item.link && !item.outWebsite && (
            <NavLink
              to={`${networkUrlParam}${item.link}`}
              className="cursor-pointer navLink">
              {item.name}
            </NavLink>
          )}
          {item.outWebsite && (
            <a
              href={item.link}
              className="cursor-pointer navLink"
              target="_blank"
              rel="noreferrer">
              {item.name}
            </a>
          )}
          {!item.link && item.name}
          {item.enabled === false && (
            <div className="flex border border-light-grey px-2 py-1 items-center rounded-20">
              <div className="rounded full w-2 h-2 bg-disable-tip mr-2" />
              <div className="text-ecosystem font-medium text-xss">
                Coming Soon
              </div>
            </div>
          )}
        </div>
        <div className="text-sm text-ecosystem font-normal leading-5 mt-1 ">
          {item.desc.split('\n').map((i, key) => {
            return <div key={key}>{i}</div>;
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="relative mt-2 p-4 flex items-center text-default bg-white rounded-lg w-full">
      <div className="flex flex-row items-start gap-4 w-full">
        <img
          src={item.icon}
          alt={item.name}
          className="w-10 h-10 rounded-full mb-1"
        />
        <EcosystemNameBlock />
      </div>
    </div>
  );
};

const ExploreEcosystem = () => {
  const { pathname } = useLocation();
  const isMantaPayPage = pathname.includes('/manta/bridge');
  if (!isMantaPayPage) {
    return null;
  }
  return (
    <div className="w-122 bg-light-blue p-6 sm:p-8 bg-secondary rounded-lg">
      <h1 className="text-xl text-default font-semibold">Explore Ecosystem</h1>
      <div className="mt-1 mb-4 text-sm text-default opacity-80">
        Explore Manta Atlantic Ecosystem instead of bridge out:
      </div>

      {EcosystemArr.map((item) => {
        return <EcosystemBlock item={item} key={item.desc} />;
      })}
    </div>
  );
};

export default ExploreEcosystem;
