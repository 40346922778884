import { useConfig } from 'contexts/configContext';
import { ethers } from 'ethers';
import ABI from './kma.json';

export const useKMAMigrateContract = () => {
  const config = useConfig();
  const KMAMigrateContractAddress = config.IS_TESTNET ? config.KMAMigrateContractOnMantaPacificTestnet : config.KMAMigrateContractOnMantaPacific

  const readContract = (provider: any) => {
    return new ethers.Contract(
      KMAMigrateContractAddress,
      ABI.abi,
      new ethers.providers.Web3Provider(provider)
    );
  };

  const writeContract = (provider: any) => {
    return new ethers.Contract(
      KMAMigrateContractAddress,
      ABI.abi,
      new ethers.providers.Web3Provider(provider).getSigner()
    );
  };

  return { readContract, writeContract };
};
