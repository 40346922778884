import classNames from 'classnames';
import Icon, { IconName } from 'components/Icon';

export const NotificationContent = ({
  subscanUrl,
  msg = 'Transaction Success',
  iconClassName = 'migrationSuccess'
}: {
  subscanUrl?: string;
  msg?: string;
  iconClassName?: IconName;
}) => {
  const onClickHandler = (url: string) => {
    if (url) {
      window.open(url, '_blank', 'noreferrer');
    }
  };

  return (
    <div>
      <div
        className={classNames('flex items-center justify-start', {
          'cursor-pointer': subscanUrl
        })}
        style={{ width: '193px' }}
        onClick={() => subscanUrl && onClickHandler(subscanUrl)}>
        <Icon name={iconClassName} style={{ width: '32px', height: '32px' }} />
        <div className="flex flex-col ml-2">
          <div
            className="font-semibold text-migration-secondary "
            style={{ fontSize: '14px', wordBreak: 'break-all' }}>
            {msg}
          </div>
          {subscanUrl && (
            <div className="flex items-center">
              <div
                style={{ color: 'rgba(32, 30, 30, 0.80)', fontSize: '12px' }}>
                View on explorer&nbsp;
              </div>
              <Icon
                name="faExternalLink"
                style={{ width: '12px', height: '12px' }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
