// @ts-nocheck
import classNames from 'classnames';
import MobileNotSupportedModal from 'components/Modal/mobileNotSupported';
import CalamariBareNavbar from 'components/Navbar/CalamariBare';
import PageContent from 'components/PageContent';
import { useNavigate } from 'react-router-dom';
import MantaFish1 from 'resources/images/manta-fish1.png';
import MantaFish2 from 'resources/images/manta-fish2.png';
import Squid from 'resources/images/squid1.png';
import userIsMobile from 'utils/ui/userIsMobile';

const MigrationLandingPage = () => {
  const navigate = useNavigate();
  let mobileNotSupportedModal = null;
  if (userIsMobile()) {
    mobileNotSupportedModal = <MobileNotSupportedModal />;
  }

  return (
    <div className="flex flex-col bg-migration min-h-screen">
      {mobileNotSupportedModal}
      <CalamariBareNavbar />
      <PageContent>
        <div className="max-w-7xl font-red-hat-text mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-start items-center space-y-6">
          <div className="relative mt-8 sm:mt-12 text-center text-migration-secondary text-96px font-semibold leading-tight">
            A New Era for Calamari
            <img
              style={{
                position: 'absolute',
                width: '425px',
                height: '309px',
                top: '194px',
                left: '-165px'
              }}
              src={MantaFish1 as string}
              alt="MantaFish1"
            />
            <img
              style={{
                position: 'absolute',
                width: '339px',
                height: '212px',
                top: '-150px',
                right: '0px',
                marginRight: '-85px'
              }}
              src={MantaFish2 as string}
              alt="MantaFish2"
            />
          </div>
          <div className="z-20 w-944px leading-21.17px text-left text-migration-first text-base font-normal">
            It's the dawn of a new chapter for Calamari Network. It began its
            roots as a community network, with a community token, $KMA, which
            has NO team or VC allocations. As a community-first project,
            Calamari Network must prioritize the strength of its community. Its
            current network, which exists on Kusama, is limited due to the
            limited user base and community on Kusama. As a result, Calamari
            Network will begin its migration over to Manta Pacific, the largest
            modular L2. Manta Pacific boasts a thriving ecosystem with more
            opportunity for community growth. Furthermore, the migration removes
            the architectural constraints of the parachain, allowing Calamari
            Network to continue growing without the need to renew leases.
            <br />
            <br />
            The circulating supply at the end of migration will become the total
            supply of Manta Pacific KMA, allowing all holders to exchange their
            tokens at a 1:1 ratio. All unreleased KMA tokens will be permanently
            destroyed. Upon completion of the migration, no new $KMA will be
            generated.
            <br />
          </div>
          <div className="h-auto sm:h-[88px] flex-col justify-start items-center gap-4 flex">
            <div
              onClick={() => navigate('/calamari/migrate')}
              className={classNames(
                'z-10 h-53px w-200px cursor-pointer p-4 bg-migration-secondary rounded-xl justify-center items-center gap-2.5 inline-flex'
              )}>
              <div className="text-center text-white text-base font-semibold">
                Migrate Now
              </div>
            </div>
            <div className="self-stretch text-center text-migration-secondary text-xs sm:text-sm font-normal px-4 sm:px-0">
              This invitation is available until February 22, 2025 at 24:00:00
              GMT.
            </div>
          </div>
          <div className="relative w-full">
            <img className="invisible w-full" src={Squid} alt="Squid" />
            <img
              style={{
                position: 'absolute',
                width: '1193px',
                height: '528px',
                top: '-150px',
                left: '-40px'
              }}
              src={Squid}
              alt="Squid"
            />
          </div>
        </div>
      </PageContent>
    </div>
  );
};

export default MigrationLandingPage;
