import axios from 'axios';

// const baseUrl =  'https://bridge-api.nonprod-bridge.manta.network';
const baseUrl =  'https://bridge-api.bridge.manta.network';

interface GenerateBindSignatureReq {
  bindAddress: 1 | 0; //	1 = bind, 0 = unbind
  pacificAddress: string;
  pacificSignature: string; //	signature of pacific address
  atlanticAddress: string;
  atlanticSignature: string; //	signature of atlantic address
}

export const generateBindSignature = (data: GenerateBindSignatureReq) => {
  return axios.post(`${baseUrl}/staker/generate_bind_signature`, data);
};
