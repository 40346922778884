import { Notification } from 'element-react';

import { NotificationContent } from './NotificationContent';

export const showError = (msg: string, subscanUrl = '') => {
  Notification({
    message: (
      <NotificationContent
        msg={msg}
        subscanUrl={subscanUrl}
        iconClassName="migrationFailed"
      />
    ),
    duration: 10000,
    offset: 70
  });
};

export const showSuccess = (subscanUrl = '') => {
  Notification({
    message: <NotificationContent subscanUrl={subscanUrl} />,
    duration: 10000,
    offset: 70
  });
};
