const TEST_MODE_DATE = 'migration_test_date'; // For testing use only
export const MIGRATION_ENDS_TEXT = 'Migration Ends';

export const isMigrationExpired = () => {
  const testDate = localStorage.getItem(TEST_MODE_DATE);
  const isValidDate = testDate && !isNaN(new Date(testDate).getTime());
  const now = isValidDate ? new Date(testDate) : new Date();
  const migrationEndDate = new Date('2025-02-22T23:59:59.000+00:00'); // GMT
  return now.getTime() > migrationEndDate.getTime();
};
