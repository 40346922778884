import classNames from 'classnames';
import Icon from 'components/Icon';
import { useConfig } from 'contexts/configContext';
import { useMetamask } from 'contexts/metamaskContext';
import { useTxStatus } from 'contexts/txStatusContext';
import { useWallet } from 'contexts/walletContext';
import { useModal } from 'hooks';
import { useEffect } from 'react';
import MantaFish1 from 'resources/images/manta-fish1.png';
import Squid3 from 'resources/images/squid3.png';
import Chain from 'types/Chain';
import { getDisplayAddress } from 'utils/display/getDisplayAddress';
import { useMigrationBurn } from './MigrationBurnContext';
import { useMigrationHistory } from './MigrationHistoryContext';
import MigrationSendBalance from './MigrationSendBalance';
import MigrationSendButton from './MigrationSendButton';

const MigrationSendForm = ({ children }: { children?: React.ReactNode }) => {
  const { claimEVMTokenStatus } = useMigrationHistory();
  const {
    ModalWrapper: ClaimEVMTokenSuccessModalWrapper,
    showModal: showSuccess
  } = useModal({
    closeDisabled: false
  });
  const {
    ModalWrapper: ClaimEVMTokenFailedModalWrapper,
    showModal: showFailed
  } = useModal({
    closeDisabled: false
  });
  const config = useConfig();
  const { ethAddress } = useMetamask();
  const { selectedAccount: externalAccount } = useWallet();
  const publicAddress = externalAccount?.address || '';
  const { addTokenToMetamask, senderAssetTargetBalance } = useMigrationBurn();
  const { txStatus } = useTxStatus();
  const disabled = txStatus?.isProcessing();
  const chain = Chain.MantaPacific(config);
  const targetNetworkName = chain?.ethMetadata?.chainName;
  const addThousandSeparator = (str: string) => {
    return str ? str.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
  };

  const senderBalance = senderAssetTargetBalance
    ? senderAssetTargetBalance?.toString()
    : 0;

  useEffect(() => {
    const checkClaimEVMTokenStatus = async () => {
      if (claimEVMTokenStatus === 'true') {
        showSuccess();
      } else if (claimEVMTokenStatus === 'false') {
        showFailed();
      }
    };
    checkClaimEVMTokenStatus();
  }, [claimEVMTokenStatus]);

  return (
    <div>
      <div className="2xl:inset-x-0 justify-center min-h-full flex flex-col gap-6 items-center pb-2 pt-12">
        <div className="relative">
          <img
            style={{
              position: 'absolute',
              width: '425px',
              height: '309px',
              top: '88px',
              left: '-321px'
            }}
            src={MantaFish1 as string}
            alt="MantaFish1"
          />
          <div
            className={classNames(
              'relative mx-12 sm:mx-0 bg-white rounded-3xl',
              {
                disabled: disabled
              }
            )}
            style={{
              width: '624px',
              padding: '48px'
            }}>
            {children}
            <div className="z-20 h-full">
              <div className="w-full text-xs flex-col justify-start items-start gap-2 inline-flex">
                <div className="self-stretch justify-between items-center inline-flex">
                  <div className="text-migration-secondary text-opacity-80 sm:text-base font-normal ">
                    Networks
                  </div>
                  <div className="justify-start items-center gap-2 flex">
                    <div className="justify-start items-center gap-1 sm:gap-2 flex">
                      <Icon name={'calamariLogo'} className="w-6 h-6" />
                      <div className="flex items-center text-migration-secondary sm:text-base font-normal ">
                        <span>Calamari </span>
                        <Icon name="arrowRight1" className="ml-2 w-4 h-4" />
                      </div>
                    </div>
                    <div className="justify-start items-center gap-1 sm:gap-2 flex">
                      <Icon name={'manta'} className="w-6 h-6" />
                      <div className="text-migration-secondary sm:text-base font-normal ">
                        {targetNetworkName}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch justify-between items-center inline-flex">
                  <div className="text-migration-secondary  text-opacity-80 sm:text-base font-normal ">
                    Tokens
                  </div>
                  <div
                    className="flex justify-start items-center gap-2"
                    style={{ lineHeight: '21px' }}>
                    <div className="flex items-center text-migration-secondary sm:text-base font-normal ">
                      <span>{senderBalance} KMA</span>
                      <Icon name="arrowRight1" className="ml-2 w-4 h-4" />
                    </div>
                    <div className="text-migration-secondary sm:text-base font-normal ">
                      {senderBalance} KMA
                    </div>
                    <Icon
                      onClick={() => addTokenToMetamask()}
                      name="metamask"
                      style={{
                        cursor: 'pointer',
                        width: '20px',
                        height: '20px'
                      }}
                    />
                  </div>
                </div>
                <div className="self-stretch justify-between items-center inline-flex">
                  <div className="text-migration-secondary  text-opacity-80 sm:text-base font-normal ">
                    Calamari Wallet
                  </div>
                  <div className="ml-2 text-right text-migration-secondary sm:text-base font-normal ">
                    {getDisplayAddress(publicAddress)}
                  </div>
                </div>
                <div className="self-stretch justify-between items-center inline-flex">
                  <div className="text-migration-secondary  text-opacity-80 sm:text-base font-normal ">
                    Manta Pacific Wallet
                  </div>
                  <div className="ml-2 text-right text-migration-secondary sm:text-base font-normal ">
                    {getDisplayAddress(ethAddress)}
                  </div>
                </div>
              </div>
              <MigrationSendBalance />
              {senderBalance ? (
                <div className="flex justify-center my-4">
                  <div className="h-[37px] px-6 py-2 bg-migration-third bg-opacity-40 rounded-lg border border-migration-third justify-center items-center gap-2.5 inline-flex">
                    <div className="text-center text-migration-secondary  text-opacity-80 text-base font-normal ">
                      🐙 You&apos;ll be able to claim{' '}
                      {addThousandSeparator(senderBalance)} KMA on Manta
                      Pacific! 🌊
                    </div>
                  </div>
                </div>
              ) : null}
              <MigrationSendButton />
            </div>
          </div>
        </div>
      </div>

      <ClaimEVMTokenSuccessModalWrapper>
        <div className="w-[458px] h-[486px] p-6 bg-white rounded-3xl flex-col justify-start items-start gap-6 inline-flex">
          <div className="self-stretch justify-start items-center gap-6 inline-flex">
            <div className="grow shrink basis-0 text-[#201e1e] text-xl font-semibold ">
              Congratulations!
            </div>
          </div>
          <div className="flex justify-center h-225px">
            <img className="w-[440px]" src={Squid3 as string} alt="Squid3" />
          </div>
          <div className="self-stretch text-center text-[#201e1e] text-base font-normal ">
            You successfully complete the migration! <br />
            You can now check your new KMA in your EVM wallet.
          </div>
          <div
            onClick={() => addTokenToMetamask()}
            className="cursor-pointer w-full h-12 rounded-xl migration-gradient-button flex items-center justify-center text-white text-base font-semibold">
            Add New KMA to Your Wallet
          </div>
        </div>
      </ClaimEVMTokenSuccessModalWrapper>

      <ClaimEVMTokenFailedModalWrapper>
        <div className="w-[458px] h-[486px] p-6 bg-white rounded-3xl flex-col justify-start items-start gap-6 inline-flex">
          <div className="self-stretch justify-start items-center gap-6 inline-flex">
            <div className="grow shrink basis-0 text-[#201e1e] text-xl font-semibold ">
              Claim Failed!
            </div>
          </div>
          <div className="self-stretch text-center text-[#201e1e] text-base font-normal ">
            Please try again in your Migration History.
          </div>
        </div>
      </ClaimEVMTokenFailedModalWrapper>
    </div>
  );
};

export default MigrationSendForm;
