// @ts-nocheck
import AccountSelectMenu from 'components/Accounts/AccountSelectMenu';
import Menu from 'components/Menu/DotMenu';
import ChainSelector from './ChainSelector';
import NavLinks from './NavLinks';

export const Navbar = () => {
  return (
    <div className="h-20 py-4 px-10 flex justify-between items-center sticky left-0 right-0 top-0 z-50 bg-nav">
      <div className="flex items-center">
        <ChainSelector className="place-self-start" />
        <NavLinks />
      </div>
      <div className="h-10 gap-4 flex flex-wrap justify-end items-center">
        <AccountSelectMenu />
        <Menu />
      </div>
    </div>
  );
};

export default Navbar;
