import React, { SVGAttributes } from 'react';
import icons from 'resources/icons';

export type IconName = keyof typeof icons;

export type IconProps = {
  name: IconName;
} & SVGAttributes<SVGElement>;

const subscriptStyles = {
  top: '-6px',
  right: '-4px',
  fontSize: '6px',
  background:
    'linear-gradient(rgba(57, 93, 242, 0.6),rgba(35, 145, 246, 0.6),rgba(176, 114, 255, 0.6))',
  borderRadius: '4px',
  color: '#fff',
  padding: '1px 2px'
};

const Icon = ({ name, ...otherProps }: IconProps) => {
  // using manta.svg as a default
  const Component = icons[name] || icons.manta;
  if (name === 'subwallet' || name === 'talisman') {
    return (
      <span className="relative">
        <Component {...otherProps} />
        <span className="absolute" style={subscriptStyles}>
          EVM
        </span>
      </span>
    );
  }
  return <Component {...otherProps} />;
};
export default Icon;
