// @ts-nocheck
import { bnToU8a } from '@polkadot/util';
import BN from 'bn.js';
import { dummyPublicAddress } from 'constants/DummyTransactions';
import { useConfig } from 'contexts/configContext';
import { useSubstrate } from 'contexts/substrateContext';
import { useTxStatus } from 'contexts/txStatusContext';
import { useWallet } from 'contexts/walletContext';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AssetType from 'types/AssetType';
import Balance from 'types/Balance';
import TxStatus from 'types/TxStatus';
import getExtrinsicGivenBlockHash from 'utils/api/getExtrinsicGivenBlockHash';
import { showError, showSuccess } from '../MigrationNotification';

const MigrationBurnContext = React.createContext();

const BurnReceiverAddress = 'dmwbyfh33EF56oJfR8Cnf7RYf2cDeNkC7m4VGzUgkwtSRbjL6';

export const MigrationBurnContextProvider = (props) => {
  const config = useConfig();
  const { api } = useSubstrate();
  const { setTxStatus, txStatus, txStatusRef } = useTxStatus();
  const { selectedAccount: externalAccount } = useWallet();
  const publicAddress = externalAccount?.address || '';
  const [publicBalance, setPublicBalance] = useState(null);
  const [senderAssetTargetBalance, setSenderAssetTargetBalance] =
    useState(null);
  const [senderAssetType] = useState(AssetType.AllCurrencies(config, false)[0]);
  const [feeEstimate, setFeeEstimate] = useState(null);
  const burnAddress = config.IS_TESTNET
    ? 'dmwbyfh33EF56oJfR8Cnf7RYf2cDeNkC7m4VGzUgkwtSRbjL6'
    : 'dmuVLQW7f2MeZ7PDfkXDtKGSHjd3mB8pePqnuwbmoE5ypssYt';
  // burn tx state
  const txQueue = useRef<SubmittableExtrinsic<'promise', any>[]>([]);
  const finalTxResHandler = useRef<txResHandlerType<any, void>>(() => {});
  const finalTxRef = useRef<string | null>(null);

  const setFinalTxRef = (tx: SubmittableExtrinsic<'promise', any>) => {
    finalTxRef.current = tx;
  };

  const _fetchPublicBalance = async (address, assetType) => {
    if (!api?.isConnected || !address || !assetType) {
      return null;
    }
    try {
      if (assetType.isNativeToken) {
        const raw = await api.query.system.account(address);
        const total = new Balance(assetType, new BN(raw.data.free.toString()));
        const staked = new Balance(
          assetType,
          new BN(raw.data.frozen.toString())
        );
        return total.sub(staked);
      } else {
        const assetBalance = await api.query.assets.account(
          assetType.assetId,
          address
        );
        if (assetBalance.value.isEmpty) {
          return new Balance(assetType, new BN(0));
        } else {
          return new Balance(
            assetType,
            new BN(assetBalance.value.balance.toString())
          );
        }
      }
    } catch (e) {
      console.error('Failed to fetch public balance', e);
      return null;
    }
  };

  const fetchPublicBalance = async () => {
    const assetType = AssetType.Calamari(config, false);
    const balance = await _fetchPublicBalance(publicAddress, assetType);
    if (balance) setPublicBalance(balance);
  };

  useEffect(() => {
    fetchPublicBalance();
  }, [publicAddress, txStatus, api?.isConnected]);

  const getPublicFeeEstimateTx = async () => {
    let tx = null;
    tx = await buildBurnTransfer(
      Balance.Native(config, new BN(1)),
      dummyPublicAddress
    );

    return tx;
  };

  const getFeeEstimate = async () => {
    if (!api || !externalAccount || !senderAssetType) {
      return;
    }
    const tx = await getPublicFeeEstimateTx();
    if (!tx) {
      return;
    }
    const paymentInfo = await tx.paymentInfo(publicAddress);
    const feeEstimate = Balance.Native(
      config,
      new BN(paymentInfo.partialFee.toString())
    );

    setFeeEstimate(feeEstimate);
  };
  useEffect(() => {
    getFeeEstimate();
  }, [api, publicAddress, senderAssetType]);

  const getMaxSendableBalance = () => {
    if (!feeEstimate) {
      return null;
    }
    if (!publicBalance) {
      return null;
    }

    const keepLiveBalance = new Balance(
      senderAssetType,
      new BN('500000000000') // 0.5 KMA (12位小数)
    );
    const zeroBalance = new Balance(senderAssetType, new BN(0));
    return Balance.max(
      publicBalance.sub(feeEstimate).sub(keepLiveBalance),
      zeroBalance
    );
  };

  // Handles the result of a transaction
  const handleTxRes = async ({ status, events }) => {
    if (status.isInBlock) {
      const extrinsic = await getExtrinsicGivenBlockHash(
        status.asInBlock,
        externalAccount,
        api
      );
      for (const event of events) {
        if (api.events.utility.BatchInterrupted.is(event.event)) {
          handleTxFailure(extrinsic);
        }
      }
    } else if (status.isFinalized) {
      for (const event of events) {
        if (api.events.utility.BatchInterrupted.is(event.event)) {
          return;
        }
      }
      await handleTxSuccess(status);
    }
  };

  const handleTxFailure = (extrinsic) => {
    // Don't show failure if the tx was interrupted by disconnection
    txStatusRef.current?.isProcessing() && setTxStatus(TxStatus.failed());
    showError('Transaction failed');
    console.error('Transaction failed', event);
  };

  const handleTxSuccess = async (status) => {
    try {
      // Don't show success if the tx was interrupted by disconnection
      const extrinsic = await getExtrinsicGivenBlockHash(
        status.asFinalized,
        externalAccount,
        api
      );
      const extrinsicHash = extrinsic.hash.toHex();
      if (txStatusRef.current?.isProcessing()) {
        setTxStatus(TxStatus.finalized(extrinsicHash, config.SUBSCAN_URL));
        const subscanUrl = `${config.SUBSCAN_URL}/extrinsic/${extrinsicHash}`;
        showSuccess(subscanUrl);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFinalTxResHandler = (
    baseTxResHandler: txResHandlerType<any>,
    finalTx: SubmittableExtrinsic<'promise', any>
  ) => {
    return async (res: any) => {
      const { status, txHash } = res;
      finalTxRef.current = res;
      if (status.isBroadcast) {
        // update transaction history
      }
      await baseTxResHandler(res, undefined);
    };
  };

  const handleInternalTxRes = async ({
    status,
    events
  }: {
    status: ExtrinsicStatus;
    events: EventRecord[];
  }) => {
    if (status.isInBlock) {
      for (const event of events) {
        if (api.events.utility.BatchInterrupted.is(event.event)) {
          setTxStatus(TxStatus.failed('Transaction failed'));
          showError('Transaction failed');
          txQueue.current = [];
          console.error('Internal transaction failed', event);
        }
      }
    } else if (status.isFinalized) {
      console.log('Internal transaction finalized');
      await publishNextBatch();
    }
  };

  const getSignAndSend = async (
    tx: SubmittableExtrinsic<'promise', any>[],
    publicAddress: string,
    extra: any,
    finalTxResHandler: txResHandlerType<any>
  ) => {
    return Array.isArray(tx)
      ? api.tx.utility
          .batchAll(tx)
          .signAndSend(publicAddress, extra, finalTxResHandler)
      : (tx as SubmittableExtrinsic<'promise', any>).signAndSend(
          publicAddress,
          extra,
          finalTxResHandler
        );
  };

  const publishNextBatch = async () => {
    const sendExternal = async () => {
      try {
        const lastTx: any = txQueue.current.shift();
        if (!lastTx) {
          console.error(new Error('can not get lastTx'));
          setTxStatus(TxStatus.failed(''));
          return;
        }
        await getSignAndSend(
          lastTx,
          publicAddress,
          {
            nonce: -1,
            signer: externalAccount?.signer,
            withSignedTransaction: true
          },
          finalTxResHandler.current
        );
        const txHash = Array.isArray(lastTx)
          ? lastTx[0].hash.toString()
          : lastTx.hash.toString();
        setTxStatus(TxStatus.processing(null, txHash));
      } catch (e) {
        console.error('Error publishing transaction batch', e);
        setTxStatus(TxStatus.failed('externalTx failed'));
        showError('Transaction failed');
        txQueue.current = [];
      }
    };

    const sendInternal = async () => {
      try {
        const internalTx: any = txQueue.current.shift();
        await getSignAndSend(
          internalTx,
          publicAddress,
          {
            nonce: -1,
            signer: externalAccount?.signer,
            withSignedTransaction: true
          },
          handleInternalTxRes
        );
      } catch (e) {
        setTxStatus(TxStatus.failed('internalTx failed'));
        showError('Internal transaction failed');
        txQueue.current = [];
      }
    };

    if (txQueue.current.length === 0) {
      return;
    } else if (txQueue.current.length === 1) {
      sendExternal();
    } else {
      sendInternal();
    }
  };

  const publishBatchesSequentially = async (
    batches: SubmittableExtrinsic<'promise', any>[],
    txResHandler: txResHandlerType<any>
  ) => {
    txQueue.current = batches;
    const finalTx = txQueue.current[txQueue.current.length - 1];
    finalTxResHandler.current = getFinalTxResHandler(txResHandler, finalTx);
    try {
      publishNextBatch();
      return true;
    } catch (e) {
      console.error('Sequential baching failed', e);
      return false;
    }
  };

  const burnTransfer = async (ethAddress: string) => {
    setTxStatus(TxStatus.processing());
    const transferTx = await buildBurnTransfer(
      senderAssetTargetBalance,
      burnAddress
    );
    const remarkTx = api.tx.system.remark(ethAddress);
    try {
      await publishBatchesSequentially([[transferTx, remarkTx]], handleTxRes);
    } catch (e) {
      setTxStatus(TxStatus.failed('Transaction declined'));
      showError('Transaction declined');
    }
  };

  const buildBurnTransfer = async (
    senderAssetTargetBalance,
    receiverAddress
  ) => {
    const assetId = senderAssetTargetBalance?.assetType?.assetId;
    const valueAtomicUnits = senderAssetTargetBalance.valueAtomicUnits;
    const assetIdArray = bnToU8a(new BN(assetId), { bitLength: 256 });
    const valueArray = valueAtomicUnits.toArray('le', 16);
    // alter way, api.tx.balances.transferKeepAlive
    const tx = await api.tx.mantaPay.publicTransfer(
      { id: assetIdArray, value: valueArray },
      receiverAddress
    );
    return tx;
  };

  const addTokenToMetamask = async () => {
    const contractAddress = config.IS_TESTNET
      ? config.KMAMigrateContractOnMantaPacificTestnet
      : config.KMAMigrateContractOnMantaPacific;
    try {
      if (typeof window !== 'undefined' && 'ethereum' in window) {
        const ethereum = window.ethereum as any;
        await ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: `${contractAddress}`,
              symbol: 'KMA',
              decimals: 18
            }
          }
        });
      } else {
        console.error('MetaMask not installed');
      }
    } catch (error) {
      console.error('Failed to add token:', error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (txStatus?.isProcessing() || !publicAddress) {
        return;
      }
      fetchPublicBalance();
      getFeeEstimate();
    }, 120000); // refetch every two mins, same logic with SendContext
    return () => clearInterval(interval);
  }, [publicAddress, txStatus?.isProcessing()]);

  const value = {
    addTokenToMetamask,
    feeEstimate,
    getMaxSendableBalance,
    senderAssetType,
    senderAssetTargetBalance,
    setSenderAssetTargetBalance,
    burnTransfer,
    finalTx: finalTxRef.current,
    setFinalTxRef,
    publicBalance
  };

  return (
    <MigrationBurnContext.Provider value={value}>
      {props.children}
    </MigrationBurnContext.Provider>
  );
};

MigrationBurnContextProvider.propTypes = {
  children: PropTypes.any
};

export const useMigrationBurn = () => ({ ...useContext(MigrationBurnContext) });
