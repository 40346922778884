import classNames from 'classnames';
import { useMetamask } from 'contexts/metamaskContext';
import { useTxStatus } from 'contexts/txStatusContext';
import { useWallet } from 'contexts/walletContext';
import { useEffect, useRef } from 'react';
import { getDisplayAddress } from 'utils/display/getDisplayAddress';
import { isMigrationExpired } from 'utils/display/getMigrationExpireTime';
import { useMigrationBurn } from './MigrationBurnContext';
import {
  HistoryRecordItem,
  useMigrationHistory
} from './MigrationHistoryContext';

const MigrationHistory = () => {
  const { txStatus, setTxStatus } = useTxStatus();
  const { selectedAccount: externalAccount } = useWallet();
  const { ethAddress, switchNetwork, provider } = useMetamask();
  const { claimStep, claimEVMToken, patchHistoryRecord, setClaimStep } =
    useMigrationHistory();
  const { finalTx, setFinalTxRef } = useMigrationBurn();
  const finalTxHash = finalTx?.txHash?.toHex();
  const publicAddress = externalAccount?.address || '';
  const hasCalledGetHistoryRecord = useRef({} as Record<string, boolean>);
  const hasPatchedHistoryRecord = useRef({} as Record<string, boolean>);
  const { historyRecord, getHistoryRecord } = useMigrationHistory();

  useEffect(() => {
    const checkNetwork = async () => {
      await switchNetwork();
    };
    if (provider) checkNetwork();
  }, [provider]);

  useEffect(() => {
    if (!publicAddress) return;
    const key = `${publicAddress}`;
    const previousKey =
      Object.keys(hasCalledGetHistoryRecord.current).pop() || null;
    const fetched = hasCalledGetHistoryRecord.current[key];
    const getHistory = async () => {
      await getHistoryRecord();
      hasCalledGetHistoryRecord.current[key] = true;
      if (previousKey) delete hasCalledGetHistoryRecord.current[previousKey];
    };
    if (!previousKey || previousKey !== key) {
      if (!fetched) {
        getHistory();
        if (claimStep) {
          // 切换dot账户后，如果用户停留在第二阶段 重置回初始状态
          setClaimStep(false);
          setFinalTxRef(null);
        }
      }
    }
  }, [publicAddress, hasCalledGetHistoryRecord]);

  useEffect(() => {
    if (!ethAddress) return;
    const key = `${ethAddress}`;
    const previousKey =
      Object.keys(hasPatchedHistoryRecord.current).pop() || null;
    const fetched = hasPatchedHistoryRecord.current[key];
    const updateHistory = async () => {
      await patchHistoryRecord(ethAddress, historyRecord);
      hasPatchedHistoryRecord.current[key] = true;
      if (previousKey) delete hasPatchedHistoryRecord.current[previousKey];
    };
    if (!previousKey || previousKey !== key) {
      if (!fetched) {
        updateHistory();
        if (claimStep) {
          // 切换evm账户后，如果用户停留在第二阶段 重置回初始状态
          setClaimStep(false);
          setFinalTxRef(null);
        }
      }
    }
  }, [ethAddress, hasPatchedHistoryRecord]);

  useEffect(() => {
    if (publicAddress && ethAddress) {
      if (txStatus?.isFailed()) {
        // 切换地址时 如果按钮为Transaction Failed 重置
        setTxStatus(null);
      }
    }
  }, [publicAddress, ethAddress]);

  const formatTimestamp = (timestamp: number | string) => {
    if (!timestamp) return '-';
    const date = new Date(Number(timestamp));
    return new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }).format(date);
  };

  const connectWallet = async () => {
    try {
      const ethereum = (window as any).ethereum;
      if (!ethereum) {
        throw new Error('Ethereum provider not detected');
      }
      const accounts = await ethereum.request({
        method: 'eth_requestAccounts'
      });

      if (accounts.length > 0) {
        const address = accounts[0];
        console.log('Connected wallet address:', address);
        return address;
      } else {
        throw new Error('No available accounts found');
      }
    } catch (error) {
      console.error('Error connecting wallet:', error);
      throw error;
    }
  };

  const handleSwitchEvm = async () => {
    try {
      if (typeof (window as any).ethereum !== 'undefined') {
        await (window as any).ethereum?.request({
          method: 'wallet_requestPermissions',
          params: [{ eth_accounts: {} }]
        });

        await connectWallet();

        console.log('Successfully switched EVM account');
      } else {
        console.error('Metamask not detected');
      }
    } catch (error) {
      console.error('Error switching EVM account:', error);
    }
  };

  const StatusButton = ({
    claimed,
    onGoing
  }: {
    claimed: boolean | undefined;
    onGoing: boolean;
  }) => {
    let statusCls = 'bg-migration-finalized border-migration-finalized';
    let text = 'Finalized';

    if (!claimed) {
      statusCls = 'bg-migration-failed border-migration-failed';
      text = 'Failed';
      if (onGoing) {
        statusCls = 'bg-migration-ongoing border-migration-ongoing';
        text = 'Ongoing';
      }
    }

    return (
      <div
        className={classNames(
          'w-20 h-[27px] px-2 py-1  rounded-full border justify-center items-center gap-2.5 inline-flex ',
          `${statusCls}`,
          'bg-opacity-40'
        )}>
        <div className="italic text-center text-migration-secondary text-sm font-normal ">
          {text}
        </div>
      </div>
    );
  };

  const LastRow = ({
    record,
    claimed,
    onGoing,
    children
  }: {
    record: HistoryRecordItem;
    claimed: boolean | undefined;
    onGoing: boolean;
    children?: React.ReactNode;
  }) => {
    const expired = isMigrationExpired();
    const evmMatched =
      record?.evm_address?.toLowerCase() === ethAddress?.toLowerCase();
    return (
      <div className="flex justify-start items-center ">
        {children}
        {!claimed && claimed !== undefined ? (
          <>
            {evmMatched ? (
              <div
                style={{ color: '#FF5E00' }}
                className={classNames(
                  'ml-2 flex-grow cursor-pointer text-center font-normal',
                  expired
                    ? 'cursor-not-allowed text-migration-secondary/80'
                    : ''
                )}
                onClick={() => {
                  if (!expired) claimEVMToken(record, true);
                }}>
                {onGoing ? (
                  ''
                ) : (
                  <>{expired ? 'Claim Expired' : 'Claim Again'}</>
                )}
              </div>
            ) : (
              <div
                className="ml-2 flex-grow cursor-pointer text-center font-normal"
                style={{ color: '#FF5E00' }}
                onClick={handleSwitchEvm}>
                Switch EVM Wallet to Claim
              </div>
            )}
          </>
        ) : null}
      </div>
    );
  };

  return (
    <div className="min-h-[450px] mt-4 px-4 sm:px-20 py-12 flex-grow m-h-[640px] bg-white opacity-80">
      <div className="text-center text-migration-secondary text-xl font-semibold ">
        Migration History
      </div>
      <div className="flex justify-center items-center">
        <div className="my-6 w-11 h-[0px] border border-bottom border-migration-secondary"></div>
      </div>

      {publicAddress && historyRecord?.length ? (
        <div className="mt-4 max-h-128 overflow-x-auto overflow-y-auto max-h-[500px]">
          <table className="w-full min-w-[640px]">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-2 text-left">Time</th>
                <th className="p-2 text-left">KMA Migrated</th>
                <th className="p-2 text-left">Source Address</th>
                <th className="p-2 text-left">Destination Address</th>
                {ethAddress ? <th className="p-2 text-left">Status</th> : null}
              </tr>
            </thead>
            <tbody>
              {historyRecord
                ?.sort((a, b) => b.timestamp - a.timestamp)
                .map((record, index) => {
                  const onGoing =
                    finalTxHash?.toLowerCase() ===
                    record?.tx_hash?.toLowerCase();
                  return (
                    <tr key={index}>
                      <td className="p-2">
                        {formatTimestamp(record.timestamp)}
                      </td>
                      <td className="p-2">{record.amount || '-'}</td>
                      <td className="p-2">
                        {getDisplayAddress(record.calamari_address) || '-'}
                      </td>
                      <td className="p-2">
                        {getDisplayAddress(record.evm_address) || '-'}
                      </td>
                      {ethAddress ? (
                        <td className="p-2">
                          <LastRow
                            record={record}
                            claimed={record.claimed}
                            onGoing={onGoing}>
                            <StatusButton
                              claimed={record.claimed}
                              onGoing={onGoing}
                            />
                          </LastRow>
                        </td>
                      ) : null}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center text-migration-secondary/80 text-base font-normal font-red-hat-text">
          No Migration History
        </div>
      )}
    </div>
  );
};

export default MigrationHistory;
